import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Toolbar } from '@mui/material';

import { NotifyTypo } from 'web-components';

import SelectedComparisonSensorCard from 'components/SensorCard/SelectedComparisonSensorCard';
import ComparisonSensorCardPlaceholder from 'components/SensorCard/ComparisonSensorCardPlaceholder';
import { ContentWrapper, TitleWrapper } from './SelectedSensorsToolbar.styled';
import { COLORS } from '../utils';

const SelectedSensorsToolbar = ({
  selectedSensorType,
  selectedSensorTypes,
  sensors,
  placeHolderKeys,
  onSelectedItemClick,
  onRemove,
  mobile
}) => {
  const { t } = useTranslation();

  const selectedSensors = selectedSensorTypes.map(sensorType => sensors.find(({ type }) => type === sensorType));

  return (
    <Toolbar disableGutters>
      <ContentWrapper>
        <TitleWrapper>
          <NotifyTypo.Body2>{t('general.graphs.selected_sensors')}</NotifyTypo.Body2>
        </TitleWrapper>
        <Grid container spacing={2} direction={mobile ? 'column' : 'row'}>
          {selectedSensors.map((sensor, key) => (
            <Grid key={sensor.type} item xs={mobile ? 12 : 4}>
              <SelectedComparisonSensorCard
                aria-controls={selectedSensorType === sensor.type ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                aria-label="sensor-List"
                data-testid="sensor-card-list-toggle"
                onCancelClick={onRemove}
                onClick={e => onSelectedItemClick(e, sensor.type)}
                sensor={sensor}
                clickable="true"
                color={COLORS[key]}
              />
            </Grid>
          ))}
          {placeHolderKeys.slice(selectedSensors.length).map(placeholderKey => (
            <Grid key={placeholderKey} item xs={mobile ? 12 : 4}>
              <ComparisonSensorCardPlaceholder
                aria-controls={selectedSensorType === placeholderKey ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                aria-label="sensor-List"
                data-testid="sensor-placeholder-card-list-toggle"
                onClick={e => onSelectedItemClick(e, placeholderKey)}
                placeholderMenu
              />
            </Grid>
          ))}
        </Grid>
      </ContentWrapper>
    </Toolbar>
  );
};

SelectedSensorsToolbar.propTypes = {
  selectedSensorType: T.string,
  selectedSensorTypes: T.arrayOf(T.string).isRequired,
  sensors: T.arrayOf(T.shape({})).isRequired,
  placeHolderKeys: T.arrayOf(T.string).isRequired,
  onSelectedItemClick: T.func.isRequired,
  onRemove: T.func.isRequired,
  mobile: T.bool
};

SelectedSensorsToolbar.defaultProps = {
  selectedSensorType: null,
  mobile: false
};

export default SelectedSensorsToolbar;
