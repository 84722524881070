import React from 'react';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { FormHelperText } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FormElements, NotifyTypo } from 'web-components';

import { showMachineState } from '../../../../redux/ui/settings/actions';
import { getSensorSettingByIds } from '../../../../redux/ui/settings/selectors';
import MachineLegendTooltip from '../MachineLegendTooltip';
import { MachineStateSelectionWrapper, CheckboxWrapper } from './elements';
import { getData, getLoadingState } from '../../../../redux/rootSelectors';
import { getSelectedMetrics, getSelectedScopedMachineHistory } from '../../../../redux/machines/selectors';
import { isSuccess } from '../../../../helpers/utils';
import { COLOR_PRIMARY } from '../../../../attrs/colors';

const MachineStateSelection = ({ ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const sensorSettings = useSelector(state => getSensorSettingByIds(state, id, 'comparison'));
  const currentMachineStateSetting = (sensorSettings || {}).showMachineState || false;
  const machineHistory = useSelector(compose(getData, getSelectedScopedMachineHistory));
  const { status } = useSelector(compose(getLoadingState, getSelectedMetrics));
  const hasMachineHistory = Boolean(machineHistory.length);
  const isMachineHistoryLoaded = isSuccess(status);
  const isMachineHistoryEmpty = isMachineHistoryLoaded && !hasMachineHistory;

  const handleClick = () => {
    dispatch(showMachineState(id, 'comparison', !currentMachineStateSetting));
  };

  return (
    <MachineStateSelectionWrapper {...props}>
      <div style={{ display: 'flex' }}>
        {!props.isMobile ? <MachineLegendTooltip /> : null}
        {!props.mobile ? (
          <NotifyTypo.Body2 style={{ marginRight: '2rem' }}>{t('dashboard.machines.legends')}</NotifyTypo.Body2>
        ) : null}
      </div>
      <CheckboxWrapper showHelperText={isMachineHistoryEmpty}>
        <FormElements.Checkbox
          data-testid="machine_state_selection_box"
          checked={!isMachineHistoryEmpty && currentMachineStateSetting}
          disabled={isMachineHistoryEmpty}
          activeColor={COLOR_PRIMARY}
          label={t('dashboard.sensors.show_machine_state_changes')}
          onChange={handleClick}
        />
        {isMachineHistoryEmpty && <FormHelperText error>{t('dashboard.sensors.no_machine_data')}</FormHelperText>}
      </CheckboxWrapper>
    </MachineStateSelectionWrapper>
  );
};
MachineStateSelection.propTypes = {
  isMobile: T.bool,
  mobile: T.bool
};

MachineStateSelection.defaultProps = {
  isMobile: false,
  mobile: false
};

export default MachineStateSelection;
