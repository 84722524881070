import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer } from '../utils';
import { LOAD_ALERTS_HISTORY, LOAD_ALERTS_HISTORY_WITH_FILTER } from './constants';

export default combineReducers({
  loadingState: loadingStateReducer([LOAD_ALERTS_HISTORY]),
  isLoadingAlertsWithFilter: loadingStateReducer([LOAD_ALERTS_HISTORY_WITH_FILTER]),
  data: typeToReducer(
    {
      [LOAD_ALERTS_HISTORY]: {
        SUCCESS: (state, { payload }) => payload
      },
      [LOAD_ALERTS_HISTORY_WITH_FILTER]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});
