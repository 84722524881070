import { put, takeLatest } from 'redux-saga/effects';

import * as constants from './constants';
import { actionStatus, getError, statusAction } from '../utils';
import { getAlertHistory } from '../../api/alertHistory';
import { addNotification } from '../ui/notifications/actions';
import { generateId } from '../../helpers/utils';
import { NOTIFICATION_TYPE_INTERACTION } from '../../attrs/notifications';
import { setAlertHistorySortOrder } from './actions';

function* handleLoadAlertHistory({ page, limit, sort, order, machineIds, periodTime, productionLines }) {
  yield put(statusAction(constants.LOAD_ALERTS_HISTORY, actionStatus.START));
  try {
    const { data } = yield getAlertHistory(page, limit, sort, order, machineIds, periodTime, productionLines);
    yield put(statusAction(constants.LOAD_ALERTS_HISTORY, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_ALERTS_HISTORY, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.alerts.internal`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

function* handleLoadAlertHistoryFiltered({ page, limit, sort, order, machineIds, periodTime, productionLines }) {
  yield put(statusAction(constants.LOAD_ALERTS_HISTORY_WITH_FILTER, actionStatus.START));
  try {
    const { data } = yield getAlertHistory(page, limit, sort, order, machineIds, periodTime, productionLines);
    yield put(setAlertHistorySortOrder(sort, order));
    yield put(statusAction(constants.LOAD_ALERTS_HISTORY_WITH_FILTER, actionStatus.SUCCESS, { payload: data }));
  } catch (err) {
    const error = getError(err);
    yield put(
      statusAction(constants.LOAD_ALERTS_HISTORY_WITH_FILTER, actionStatus.ERROR, {
        message: error
      })
    );

    yield put(
      addNotification({
        key: generateId(),
        type: actionStatus.ERROR,
        message: `errors.alerts.internal`,
        notificationType: NOTIFICATION_TYPE_INTERACTION
      })
    );
  }
}

export function* watchLoadOrders() {
  yield takeLatest(constants.LOAD_ALERTS_HISTORY, handleLoadAlertHistory);
  yield takeLatest(constants.LOAD_ALERTS_HISTORY_WITH_FILTER, handleLoadAlertHistoryFiltered);
}
