import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getGraphsTemplateById, loadSensorHistory, loadStatusHistory } from 'redux/graphTemplates/actions';
import {
  getDateRange,
  getFormattedDate,
  PERIOD_TYPE_CUSTOM_RANG,
  PERIOD_TYPE_LAST_8_HOURS,
  periodTypeList
} from 'attrs/chartsTemplate';
import { getGraphsTemplatesReducerState } from 'redux/rootSelectors';
import { getMachineStatusProps, isSuccess } from 'helpers/utils';
import { useParams } from 'react-router-dom';
import ReactEcharts from 'echarts-for-react';
import { getMillSensor } from 'attrs/sensorType';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CustomDateDialog from 'components/CustomDateDialog';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { MACHINE_STATUS_SIMPLE_MAP } from 'attrs/notifications';
import { calculateValue, determineDirection, findClosestStatusDate, sideSpace, statusMap } from 'helpers/chartUtils';
import PropTypes from 'prop-types';
import MobileSensorPortrait from 'components/elements/MobileSensorPortrait';
import { getValueToNullableCell } from 'helpers/stringHandler';
import { COLOR_PRIMARY } from 'attrs/colors';

// Total height of chart grid in pixels
const chartPixelHeight = 410;
// Total height of chart container in pixels
const chartPixelHeightContainer = 580;

export default function SensorChart({ machineId, sensorParam }) {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [interval, setInterval] = useState(PERIOD_TYPE_LAST_8_HOURS);
  const [customPeriodStart, setCustomPeriodStart] = useState();
  const [customPeriodEnd, setCustomPeriodEnd] = useState();
  const chartRef = useRef(null);

  const xAxisDefaultValue = [
    {
      type: 'time',
      boundaryGap: false,
      axisLabel: {
        formatter: '{dd}/{MM}/{yyyy}\n{hh}:{mm}:{ss}'
      },
      axisPointer: {
        show: true,
        type: 'line',
        lineStyle: {
          color: 'gray',
          width: 1,
          type: 'dashed',
          shadowBlur: 3,
          shadowColor: 'rgba(0, 0, 0, 1)'
        }
      }
    }
  ];

  const yAxisDefaultValue = [
    {
      data: []
    }
  ];

  const seriesDefaultValue = [
    {
      data: [],
      type: 'line'
    }
  ];

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { templateId } = useParams();

  const [loadSensorParamsList, setLoadSensorParamsList] = useState([]);
  const [loadStatusParam, setLoadStatusParam] = useState();

  const loadSensorHistoryStatusEqual = (oldObj, newObj) =>
    oldObj?.loadSensorHistoryStatus?.status === newObj?.loadSensorHistoryStatus?.status;
  const { loadSensorHistoryStatus } = useSelector(getGraphsTemplatesReducerState, loadSensorHistoryStatusEqual);

  const sensorHistoryResponseEqual = (oldObj, newObj) => {
    const sameSize = oldObj?.sensorHistoryResponse?.size === newObj?.sensorHistoryResponse?.size;
    const biggerThenZero = oldObj?.sensorHistoryResponse?.size > 0;
    const equalsObj = Array.from(oldObj?.sensorHistoryResponse?.keys()).every(
      key =>
        JSON.stringify(oldObj?.sensorHistoryResponse?.get(key) || '') ===
        JSON.stringify(newObj?.sensorHistoryResponse?.get(key) || '')
    );
    return sameSize && equalsObj && !biggerThenZero;
  };
  const { sensorHistoryResponse } = useSelector(getGraphsTemplatesReducerState, sensorHistoryResponseEqual);

  const { loadStatusHistoryStatus, statusHistoryResponse } = useSelector(getGraphsTemplatesReducerState);
  const [sensorHistoryMap, setSensorHistoryMap] = useState(new Map());
  const [statusHistoryList, setStatusHistoryList] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const [series, setSeries] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);
  const didMount = useRef(false);
  const [openCustomDateDialog, setOpenCustomDateDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingTimeOut, setLoadingTimeOut] = useState(0);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [checkedShowMachineStatus, setCheckedShowMachineStatus] = useState(true);
  const [dataZoomRange, setDataZoomRange] = useState({ start: 0, end: 100 });
  const [forceReload, setForceReload] = useState(false);

  const language = useMemo(() => i18n.language, [i18n.language]);

  /**
   * A specific function to find sensor attributes, can be used only in this component
   * @param {*} resultsParam
   * @returns
   */
  function findSensorAttributes(resultsParam) {
    return resultsParam.map(result => {
      const millSensor = getMillSensor(sensorParam.type);
      const name = t(millSensor?.name || sensorParam?.name);
      const unit = millSensor?.unit || sensorParam?.custom_unit || sensorParam?.unit || 'n/a';
      return {
        name,
        unit,
        color: COLOR_PRIMARY,
        value: result.closestEntry?.v
      };
    });
  }

  useEffect(() => {
    setSeries([]);
    // needed to work with the status line
    setYAxis([
      {
        isStatusYAxis: true,
        type: 'value',
        min: 0,
        max: 100,
        show: false // hide this axis visually
      }
    ]);
    setLoading(true);
    const [pastDate, currentDate] =
      interval !== PERIOD_TYPE_CUSTOM_RANG ? getDateRange(interval) : [customPeriodStart, customPeriodEnd];
    const tempSensorParamsList = [];
    const tempStatusParam = {
      machineId,
      from: pastDate,
      to: currentDate
    };

    const millSensor = getMillSensor(sensorParam?.type);
    const name = millSensor?.name || sensorParam?.name;
    const unit = millSensor?.unit || sensorParam?.custom_unit || sensorParam?.unit || 'n/a';
    const transLatedName = t(`${name}`);

    tempSensorParamsList.push({
      sensorId: transLatedName,
      machineId,
      from: pastDate,
      to: currentDate,
      unit,
      sensor_type: sensorParam?.type
    });

    const yAxisSensor = {
      sensorId: transLatedName,
      min: 'dataMin',
      max: 'dataMax',
      type: 'value',
      name: unit,
      show: false,
      axisLine: {
        show: true,
        lineStyle: {
          color: COLOR_PRIMARY
        }
      },
      nameRotate: 45,
      nameTextStyle: {
        fontSize: 14,
        fontWeight: 'bold'
      },
      axisLabel: {
        show: true
      },
      splitLine: {
        show: false
      }
    };
    const seriesSensor = {
      sensorId: transLatedName,
      name: transLatedName,
      type: 'line',
      symbol: 'none',
      color: COLOR_PRIMARY,
      lineStyle: {
        width: 1,
        simplify: true,
        opacity: 1
      },
      yAxisIndex: 1,
      data: [],
      show: true,
      itemStyle: { opacity: 1 },
      progressive: 5000,
      progressiveThreshold: 10000,
      large: true,
      largeThreshold: 2000,
      z: 2,
      zlevel: 0
    };
    setYAxis(prevYAxis => (!prevYAxis?.length ? [yAxisSensor] : [...prevYAxis, yAxisSensor]));
    setSeries(prevSeries => (!prevSeries?.length ? [seriesSensor] : [...prevSeries, seriesSensor]));
    setLoadSensorParamsList(tempSensorParamsList);
    setLoadStatusParam(tempStatusParam);
  }, [setLoadSensorParamsList, setLoadStatusParam, forceReload]);

  // when changes period type
  useEffect(() => {
    if (interval && (interval !== PERIOD_TYPE_CUSTOM_RANG || (customPeriodStart && customPeriodEnd))) {
      if (didMount.current) {
        setLoading(true);
        setDataZoomRange({ start: 0, end: 100 });
        setForceReload(!forceReload);
      } else {
        // This code will run only on initial mount
        didMount.current = true;
      }
    }
  }, [interval, customPeriodStart, customPeriodEnd]);

  useEffect(() => {
    dispatch(loadSensorHistory(loadSensorParamsList));
  }, [dispatch, loadSensorHistory, loadSensorParamsList]);

  useEffect(() => {
    if (isSuccess(loadSensorHistoryStatus.status)) {
      setSensorHistoryMap(sensorHistoryResponse);
    }
  }, [loadSensorHistoryStatus, sensorHistoryResponse]);

  useEffect(() => {
    if (sensorHistoryMap.size) {
      sensorHistoryMap.forEach((value, key) => {
        const type = sensorParam?.type;
        const customUnit = sensorParam?.custom_unit;
        const unit = sensorParam?.unit;
        const millSensor = getMillSensor(type);
        const unitDef = millSensor?.unit || customUnit || unit || 'n/a';

        const newValue =
          value?.map(m => ({
            name: new Date(m.t).toISOString(),
            value: [new Date(m.t).toISOString(), m.v],
            unit: unitDef,
            machineId,
            sensorId: key,
            time: m.t
          })) || [];
        setSeries(prevSeries => {
          const updatedSeries = prevSeries.map(s => {
            if (s?.name === key) {
              return { ...s, data: newValue };
            }
            return s;
          });
          return updatedSeries;
        });
      });
      let yAxisIndex = 0;
      setYAxis(prevYAxis =>
        prevYAxis.map(y => {
          const willShow = sensorHistoryMap.has(y.sensorId);
          const ny = {
            ...y,
            show: willShow,
            position: determineDirection(yAxisIndex),
            offset: calculateValue(yAxisIndex)
          };
          if (willShow) yAxisIndex += 1;
          return ny;
        })
      );
    }
  }, [sensorHistoryMap, setSeries]);

  useEffect(() => {
    dispatch(loadStatusHistory(loadStatusParam));
  }, [dispatch, loadStatusHistory, loadStatusParam]);

  useEffect(() => {
    if (isSuccess(loadStatusHistoryStatus.status) && !updateStatus) {
      setStatusHistoryList(statusHistoryResponse);
      setUpdateStatus(true);
    }
  }, [loadStatusHistoryStatus, statusHistoryResponse]);

  useEffect(() => {
    // 'hasStatusSeries' is only used because of hot reload in strict mode
    const hasStatusSeries = series.some(s => s.name.includes('STATUS-'));
    if (sensorHistoryMap?.size && statusHistoryList?.length && updateStatus && !hasStatusSeries) {
      setUpdateStatus(false);
      const statusPeriods = [];
      statusHistoryList.forEach((status, index) => {
        const nextItem = index < statusHistoryList.length - 1 ? statusHistoryList[index + 1] : status;
        const currentDate = new Date(status.t);
        const strCurrentDate = currentDate.toISOString();
        const nextDate = new Date(nextItem.t);
        const strNextDate = nextDate.toISOString();
        statusPeriods.push({
          start: strCurrentDate,
          end: strNextDate,
          status: status.v,
          color: statusMap[status.v],
          warning: status.w
        });
      });

      const statusSeries = Object.values(
        statusPeriods.reduce((acc, period) => {
          const statusKey = `STATUS-${period.status}`;

          if (!acc[statusKey]) {
            acc[statusKey] = {
              name: statusKey,
              type: 'line',
              smooth: false,
              show: checkedShowMachineStatus,
              symbol: 'none',
              yAxisIndex: 0,
              z: 1,
              zlevel: 0,
              lineStyle: {
                width: 12,
                color: period.color,
                simplify: true,
                opacity: checkedShowMachineStatus ? 0.6 : 0
              },
              data: []
            };
          }

          // Start the period with the beginning point
          acc[statusKey].data.push({
            name: new Date(period.start).toISOString(),
            value: [new Date(period.start).toISOString(), 1.6],
            time: period.start
          });

          // End the period with the ending point and add a null to create a gap
          acc[statusKey].data.push(
            {
              name: new Date(period.end).toISOString(),
              value: [new Date(period.end).toISOString(), 1.6],
              time: period.end
            },
            null // gap between this and the next period
          );

          const hasWarning = period.warning;
          const warningKey = 'STATUS-WARNING';

          if (hasWarning) {
            if (!acc[warningKey]) {
              acc[warningKey] = {
                name: warningKey,
                type: 'line',
                smooth: false,
                show: true,
                symbol: 'none',
                yAxisIndex: 0,
                z: 1,
                zlevel: 0,
                lineStyle: {
                  width: 6,
                  color: '#F8CC84',
                  simplify: true,
                  opacity: 0.6
                },
                data: []
              };
            }

            acc[warningKey].data.push({
              name: new Date(period.start).toISOString(),
              value: [new Date(period.start).toISOString(), 3.9],
              time: period.start
            });

            acc[warningKey].data.push(
              {
                name: new Date(period.end).toISOString(),
                value: [new Date(period.end).toISOString(), 3.9],
                time: period.end
              },
              null
            );
          }

          return acc;
        }, {})
      );

      // Update the series with combined status entries
      setSeries(prevSeries => (!prevSeries?.length ? statusSeries : [...prevSeries, ...statusSeries]));

      // Calculate the total number of items from the biggest array in the Map
      const totalItems = Array.isArray(sensorHistoryMap)
        ? sensorHistoryMap.reduce((maxLength, array) => Math.max(maxLength, array?.length || 0), 0)
        : 0;

      // Set a delay based on the totalItems
      let delay = totalItems * 0.3;
      // Set a minimum delay of 3 seconds time needed to load the chart
      delay = delay < 3000 ? 3000 : delay;

      clearTimeout(loadingTimeOut);
      setLoadingTimeOut(
        setTimeout(() => {
          setLoading(false);
        }, delay)
      );
    } else if (!sensorHistoryMap?.size) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [sensorHistoryMap, statusHistoryList]);

  // need to make this to unable zoom out of range %0 to %100
  const validateZoomRange = (start, end) => ({
    start: Math.max(0, Math.min(start, 100)),
    end: Math.max(0, Math.min(end, 100))
  });

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  useEffect(() => {
    const chartInstance = chartRef?.current?.getEchartsInstance();
    if (chartInstance) {
      // override of zoom method to avoid zoom out of range
      const handleZoom = params => {
        const zoom = params.batch ? params.batch[0] : params;
        setDataZoomRange(validateZoomRange(zoom.start, zoom.end));
      };
      chartInstance.on('dataZoom', handleZoom);

      // debounce function for resizing the chart to avoid frequent updates
      const handleResize = debounce(() => {
        if (chartRef.current) {
          chartRef.current.getEchartsInstance().resize();
        }
      }, 200);
      window.addEventListener('resize', handleResize);

      return () => {
        chartInstance.off('dataZoom', handleZoom);
        window.removeEventListener('resize', handleResize);
      };
    }
    return () => undefined;
  }, [chartRef, validateZoomRange, debounce]);

  function getLeftPadding() {
    return sideSpace(sensorHistoryMap?.size)[0];
  }

  function getRightPadding() {
    return sideSpace(sensorHistoryMap?.size)[1];
  }

  const options = useMemo(
    () => ({
      textStyle: {
        fontFamily: 'Roboto, sans-serif'
      },
      tooltip: {
        trigger: 'axis',
        backgroundColor: 'rgba(43, 47, 66, 0.96)',
        textStyle: {
          color: '#ffffff',
          fontFamily: 'Roboto, sans-serif'
        },
        confine: true, // Ensure tooltip stays within chart area
        position(point, _params, _dom, _rect, size) {
          // Point is the mouse position, size is the size of the tooltip
          const [x, y] = point;
          const { contentSize } = size;
          const posX = x + contentSize[0] > size.viewSize[0] - 42 ? x - contentSize[0] - 42 : x + 42;
          const posY = y + contentSize[1] > size.viewSize[1] ? y - contentSize[1] : y;
          return [posX, posY];
        },
        formatter(params) {
          let result = '';
          const currentTooltipTime = params[0].axisValue;
          if (statusHistoryList.length) {
            const status = findClosestStatusDate(currentTooltipTime, statusHistoryList);
            if (status?.v) {
              const hasWarning = status.w;
              const statusDetail = getMachineStatusProps(status.v);
              const statusWarning = t('enum.machine_status.WARNING').toUpperCase();
              const statusDesc = t(`enum.machine_status.${statusDetail.value}`).toUpperCase();
              const ColorSquare = `
                <span
                  style="
                    display:inline-block;
                    margin-right:5px;
                    width:10px;
                    height:10px;
                    background-color:${statusDetail.color};
                  "
                ></span>`;
              const Chip = hasWarning
                ? `
                  <div
                    style="
                      background-color: #ffcc00;
                      color: white;
                      padding: 3px 6px;
                      border-radius: 16px;
                      display: inline-block;
                      font-size: 12px;
                      line-height: 12px;
                    "
                  >
                    ${statusWarning}
                  </div>
                `
                : '';
              result += `${ColorSquare}${statusDesc}${Chip}<br/><br/>`;
            }
          }

          // Extract all sensorIds from params
          const sensorIdsInParams = new Set(params.map(p => p.data.sensorId));

          // Filter the map to exclude entries with sensorIds in params
          const filteredSensorHistory = [...sensorHistoryMap].filter(([sensorId]) => !sensorIdsInParams.has(sensorId));

          // Find the closest time for each filtered entry
          const nearestSensors = filteredSensorHistory.map(([sensorId, historyList]) => {
            // Check if historyList is empty before trying to reduce
            if (!historyList || historyList.length === 0) {
              return {
                sensorId,
                closestEntry: null // or handle this case as needed
              };
            }
            // Find the entry with the closest time to targetTime
            const closestEntry = historyList.reduce((prev, curr) =>
              Math.abs(curr.t - currentTooltipTime) < Math.abs(prev.t - currentTooltipTime) ? curr : prev
            );
            return {
              sensorId,
              closestEntry
            };
          });

          // Iterate over params and find the sensor details
          const paramAttributes = params.reduce((acc, param) => {
            if (!param.seriesName.includes('STATUS-')) {
              acc.push({
                name: param.seriesName,
                unit: param.data.unit,
                color: param.color,
                value: param.value[1]
              });
            }
            return acc;
          }, []);

          // Iterate over graphTemplate sensors list to get sensor details
          const nearestSensorsAttribute = findSensorAttributes(nearestSensors);

          // Combine sensors in params and nearest sensors
          const combinedList = paramAttributes.concat(nearestSensorsAttribute);

          // Sort sensorAttributes alphabetically by 'name'
          combinedList.sort((a, b) => a.name.localeCompare(b.name));

          const solidLine = '<div style="border-top: 2px solid #FFFFFF;margin: 6px 0;"></div>';
          result += combinedList
            .map(
              (sensor, i) => `
                ${i > 0 ? solidLine : ''}
                <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;
                height:10px;background-color:${sensor.color};"></span>${sensor.name}<br/>
                <span style="font-size:16px;font-weight:bold;margin: 6px 0;">${getValueToNullableCell(sensor.value)} ${
                sensor.unit
              }</span>`
            )
            .join('<br/>');
          if (combinedList.length > 0) result += solidLine;
          result += `<p style="margin: 3px 0;"> ${getFormattedDate(currentTooltipTime)}</p>`;
          return result;
        }
      },
      grid: {
        top: '80px',
        left: getLeftPadding(),
        right: getRightPadding(),
        height: `${chartPixelHeight}px`
      },
      xAxis: xAxisDefaultValue,
      yAxis: yAxis.length > 0 ? yAxis : yAxisDefaultValue,
      dataZoom: [
        {
          type: 'slider',
          filterMode: 'none', // Prevents the chart from clipping the line when zooming
          start: dataZoomRange.start,
          end: dataZoomRange.end,
          labelFormatter(value) {
            return `${getFormattedDate(value)}   `;
          }
        }
      ],
      series: series.length > 0 ? series : seriesDefaultValue
    }),
    [yAxis, series, statusHistoryList, sensorHistoryMap]
  );

  const onChangeInterval = event => {
    const intervalParam = event.target.value;
    if (intervalParam !== PERIOD_TYPE_CUSTOM_RANG) setInterval(intervalParam);
  };

  const handleOpenCustomDateDialog = () => {
    setOpenCustomDateDialog(true);
  };

  const handleCloseCustomDateDialog = () => {
    setOpenCustomDateDialog(false);
  };

  const handleSetValuesCustomPeriod = (customPeriodStartParam, customPeriodEndParam) => {
    setInterval(PERIOD_TYPE_CUSTOM_RANG);
    setCustomPeriodStart(customPeriodStartParam);
    setCustomPeriodEnd(customPeriodEndParam);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const toggleStatusVisibility = prevSeries =>
    prevSeries.map(s => {
      if (s.name.includes('STATUS-')) {
        const show = !s.show;
        return {
          ...s,
          show,
          lineStyle: { ...s.lineStyle, opacity: show ? 0.6 : 0 }
        };
      }
      return s;
    });

  const handleCheckboxChange = event => {
    setCheckedShowMachineStatus(event.target.checked);
    setSeries(prevSeries => toggleStatusVisibility(prevSeries));
  };

  const handleRefreshInfo = () => {
    dispatch(getGraphsTemplateById(templateId));
    setForceReload(!forceReload);
  };

  return (
    <Paper
      sx={{
        overflow: 'hidden',
        width: '100%',
        p: { xs: 1, sm: 2, md: 2, lg: 3 }
      }}
      data-selector="chart-template-details"
    >
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4} sx={{ order: { xs: 1, sm: 1, md: 1, lg: 1 } }}>
          <FormControl variant="filled" sx={{ minWidth: { xs: '100%', sm: '100%', md: '305px' } }} disabled={loading}>
            <InputLabel id="template-label">{t('chart_template_details.filter_time')}</InputLabel>
            <Select
              IconComponent={KeyboardArrowDownIcon}
              labelId="period-type-label"
              id="period-type-select"
              onChange={onChangeInterval}
              value={interval}
            >
              {periodTypeList.map(periodType => (
                <MenuItem key={periodType} value={periodType}>
                  {t(`enum.chart_templates.period.${periodType}`)}
                </MenuItem>
              ))}
              <MenuItem
                key={`${PERIOD_TYPE_CUSTOM_RANG}`}
                value={`${PERIOD_TYPE_CUSTOM_RANG}`}
                onClick={handleOpenCustomDateDialog}
              >
                {t('enum.chart_templates.period.PERIOD_TYPE_CUSTOM_RANG')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {!isDownMd && (
          <Grid item md={2} lg="auto" sx={{ order: { md: 3, lg: 2 } }}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  arrow
                  PopperProps={{
                    disablePortal: true
                  }}
                  onClose={handleTooltipClose}
                  open={openTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <Box>
                      {MACHINE_STATUS_SIMPLE_MAP.map(status => (
                        <Box
                          key={status.value}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 1
                          }}
                        >
                          <Box
                            sx={{
                              width: 16,
                              height: 16,
                              backgroundColor: status.color,
                              marginRight: 1
                            }}
                          />
                          <Typography variant="body2">{t(`enum.machine_status.${status.value}`)}</Typography>
                        </Box>
                      ))}
                    </Box>
                  }
                >
                  <Button
                    sx={{
                      textTransform: 'none'
                    }}
                    startIcon={<InfoOutlinedIcon />}
                    onClick={handleTooltipOpen}
                  >
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#000000'
                      }}
                    >
                      {t('chart_template_details.legend')}
                    </Typography>
                  </Button>
                </Tooltip>
              </div>
            </ClickAwayListener>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={10} lg="auto" sx={{ order: { xs: 3, sm: 3, md: 4, lg: 3 } }}>
          <FormControlLabel
            control={<Checkbox checked={checkedShowMachineStatus} onChange={handleCheckboxChange} />}
            label={t('chart_template_details.show_machine_status')}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg="auto"
          sx={{
            order: { xs: 2, sm: 2, md: 2, lg: 4 },
            display: 'flex',
            justifyContent: { sm: 'flex-start', md: 'flex-end' },
            marginTop: { xs: 2, sm: 2, md: 0, lg: 0 }
          }}
        >
          <Button startIcon={<RefreshOutlinedIcon />} onClick={handleRefreshInfo}>
            <Typography
              sx={{
                fontSize: '14px'
              }}
            >
              {t('chart_template_details.refresh_info')}
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Stack spacing={4}>
        {isDownSm ? (
          <Box sx={{ pt: 4 }}>
            <MobileSensorPortrait />
          </Box>
        ) : (
          <div style={{ position: 'relative' }} id="graph-template">
            {loading && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1000
                }}
              >
                <CircularProgress disableShrink />
              </div>
            )}
            <ReactEcharts
              ref={chartRef}
              data-selector="chart-template-canvas"
              option={options}
              opts={{ renderer: 'canvas' }}
              notMerge
              lazyUpdate
              style={{
                minHeight: `${chartPixelHeightContainer}px`,
                position: 'relative',
                overflow: 'hidden'
              }}
            />
          </div>
        )}
      </Stack>
      <CustomDateDialog
        open={openCustomDateDialog}
        handleClose={handleCloseCustomDateDialog}
        title={t('custom_date_dialog.title')}
        subTitle={t('custom_date_dialog.subtitle')}
        startTimeCaption={t('custom_date_dialog.start_time')}
        endTimeCaption={t('custom_date_dialog.end_time')}
        cancelCaption={t('default_actions.cancel')}
        okCaption={t('default_actions.apply')}
        setValues={handleSetValuesCustomPeriod}
        maxDays={6}
        language={language}
      />
    </Paper>
  );
}

SensorChart.propTypes = {
  machineId: PropTypes.string.isRequired,
  sensorParam: PropTypes.shape().isRequired
};
