// External libraries
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// UI components
import Button from '@mui/material/Button';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { DataTable } from 'web-components';

// Utilities and helpers
import { isInitial as isInitialState, isLoading as isLoadingState } from 'helpers/utils';
import { RootState } from 'redux/rootCombinedReducers';
import { iResponse } from 'types/interfaces/iResponse';
import { sortFn } from 'helpers/sorter';

// Constants and styles
import { COLOR_PRIMARY, SECONDARY_TEXT_COLOR, TEXTAREA_BACKGROUND_DISABLED, WHITE } from 'attrs/colors';
import { PAGINATION_DEFAULT_ROWS_PER_PAGE, PAGINATION_DEFAULT_START_PAGE } from '../../attrs/pagination';
import {
  LIST_ORDER,
  // Explanation: ALARM_HISTORY_DEFAULT_LIST_SORT is a alert sorter
  ALARM_HISTORY_DEFAULT_LIST_SORT as ALERT_SORT,
  // Explanation: ALERT_HISTORY_DEFAULT_LIST_SORT is a alarm sorter
  ALERT_HISTORY_DEFAULT_LIST_SORT as ALARM_SORT
} from '../../attrs/sorter_default_values';

// Redux actions and selectors
// Explanation: isLoadingAlarmsWithFilter is a alert selector
import { isLoadingAlarmsWithFilter as isLoadingAlertsSelector } from '../../redux/alarms_history/selectors';
// Explanation: isLoadingAlertsWithFilter is a alarm selector
import { isLoadingAlertsWithFilter as isLoadingAlarmsSelector } from '../../redux/alert_history/selectors';
// Explanation: getAlarmsFiltered load alerts
import { getAlarmsFiltered as loadAlerts } from '../../redux/alarms_history/actions';
// Explanation: loadAlertsHistoryFiltered load alarms
import { loadAlertsHistoryFiltered as loadAlarms } from '../../redux/alert_history/actions';

// Configurations and local modules
import Footer from '../DataTable/Footer';
import { generateAlarmColumns, generateAlertColumns } from './MessageHistoryOverview.config';
import { periodObject } from './periodObject';

// don`t bother trying to create a specific type for machines or any other object
// it changes all over, it dependes on the backend response
interface Machine {
  production_line: string;
  id: string;
}

/**
 * MessageHistoryOverviewProps
 *
 * Props for the MessageHistoryOverview component.
 */
interface MessageHistoryOverviewProps {
  machine: Machine;
  isAlarms: boolean;
}

/**
 * MessageHistoryOverview Component
 *
 * This component displays a table of alarm or alert history based on the `isAlarms` prop.
 *
 * ## Warning:
 * - Some explanations made around the code to help you understand the code.
 * - It was made to avoid changes of external code to obey or deadlines.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Object} props.machine - The machine associated with the history data
 * @param {boolean} [props.isAlarms=false] - Determines whether the component is loading alarms (true) or alerts (false)
 *
 * @example
 * <MessageHistoryOverview machine={machineData} isAlarms />
 *
 * @returns {JSX.Element} A table displaying historical alarms or alerts with filtering and pagination options.
 */
const MessageHistoryOverview: React.FC<MessageHistoryOverviewProps> = ({ machine, isAlarms = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Explanation: alertsHistory is the state of alarms
  const selectAlarms = (state: RootState) => state.alertsHistory;
  // Explanation: alarmsHistory is the state of alerts
  const selectAlerts = (state: RootState) => state.alarmsHistory;
  const history = useSelector((state: RootState) => (isAlarms ? selectAlarms(state) : selectAlerts(state)));
  const rows: iResponse = history?.data || { data: [], page: 1, total_pages: 1 };
  const rowsData = Array.isArray(rows?.data) ? rows.data : [];
  const isLoadingAlerts = useSelector(isLoadingAlertsSelector);
  const isLoadingAlarms = useSelector(isLoadingAlarmsSelector);

  const isLoadingHistory = isAlarms ? isLoadingAlarms : isLoadingAlerts;

  const defaultCurrentSort = useMemo(
    () => ({
      colId: isAlarms ? ALARM_SORT : ALERT_SORT,
      order: LIST_ORDER
    }),
    [isAlarms]
  );
  const [currentSort, setCurrentSort] = useState(defaultCurrentSort);
  useEffect(() => {
    setCurrentSort(defaultCurrentSort);
  }, [defaultCurrentSort]);
  const defaultFilterValues = useMemo(
    () => ({
      productionLine: machine?.production_line || '',
      machine: machine?.id || '',
      periodTime: '',
      page: PAGINATION_DEFAULT_START_PAGE
    }),
    [machine]
  );
  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const [isLoading, setIsLoading] = useState(false);

  const loader = useCallback(() => {
    if (filterValues?.machine && filterValues?.productionLine) {
      setIsLoading(true);
      if (isAlarms) {
        dispatch(
          loadAlarms(
            filterValues.page,
            PAGINATION_DEFAULT_ROWS_PER_PAGE,
            currentSort.colId,
            currentSort.order.toUpperCase(),
            filterValues.machine,
            filterValues.periodTime,
            filterValues.productionLine
          )
        );
      } else {
        dispatch(
          loadAlerts(
            filterValues.page,
            PAGINATION_DEFAULT_ROWS_PER_PAGE,
            currentSort.colId,
            currentSort.order.toUpperCase(),
            filterValues.machine,
            filterValues.periodTime,
            filterValues.productionLine
          )
        );
      }
    }
  }, [dispatch, filterValues, currentSort]);

  useEffect(() => {
    if (!isInitialState(isLoadingHistory?.status) && !isLoadingState(isLoadingHistory?.status)) {
      setIsLoading(false);
    }
  }, [isLoadingHistory?.status]);

  useEffect(() => {
    loader();
  }, []);

  const isInitializeRef = useRef(false);
  useEffect(() => {
    if (isInitializeRef.current) {
      loader();
    } else {
      isInitializeRef.current = true;
    }
  }, [filterValues, currentSort, loader]);

  const handleChangePage = (page: number) => {
    setFilterValues(prev => ({
      ...prev,
      page
    }));
  };

  const handleSort = (colId: string, order: string) => {
    setCurrentSort({ colId, order });
  };

  const handleClearFilter = () => {
    setFilterValues(defaultFilterValues);
  };

  const props = useMemo(
    () => ({
      isLoading,
      columns: isAlarms ? generateAlarmColumns(t) : generateAlertColumns(t),
      rows: sortFn(rowsData, currentSort.colId, currentSort.order),
      footer: (
        <Footer
          currentPage={rows?.page || PAGINATION_DEFAULT_START_PAGE}
          totalPages={rows?.total_pages || 1}
          totalItems={PAGINATION_DEFAULT_ROWS_PER_PAGE}
          onChange={handleChangePage}
        />
      ),
      defaultSort: defaultCurrentSort,
      sortFn: handleSort,
      dataSelectorTable: 'alarm-history-table',
      dataSelectorRows: 'alarm-history-table-row'
    }),
    [isLoading, isAlarms, t, rows, handleChangePage, currentSort, handleSort]
  );

  const onChangeAlarmFilter = (event: SelectChangeEvent) => {
    setFilterValues(prev => ({
      ...prev,
      periodTime: event.target.value,
      page: PAGINATION_DEFAULT_START_PAGE
    }));
  };

  return (
    <Stack spacing={4} sx={{ pt: 3 }}>
      <Stack direction="row" spacing={3}>
        <FormControl
          size="small"
          variant="filled"
          sx={{
            '& .MuiSelect-icon': {
              color: COLOR_PRIMARY,
              transform: 'none !important'
            },
            '& > .MuiFilledInput-root': {
              backgroundColor: WHITE,
              '&.Mui-focused': {
                backgroundColor: WHITE
              },
              '&.Mui-disabled': {
                backgroundColor: TEXTAREA_BACKGROUND_DISABLED,
                color: SECONDARY_TEXT_COLOR
              },
              '& .MuiFilledInput-input:focus': {
                backgroundColor: WHITE
              }
            }
          }}
        >
          <InputLabel id="alarm-filter-label">{t('alarms.filter.period')}</InputLabel>
          <Select
            data-selector="alarm-filter"
            value={filterValues?.periodTime}
            onChange={onChangeAlarmFilter}
            style={{ minWidth: '380px' }}
            IconComponent={iconProps => <FilterAltOutlinedIcon {...iconProps} />}
          >
            <MenuItem value="">
              <em style={{ color: 'transparent' }}>None</em>
            </MenuItem>
            {periodObject.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {t(item.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button color="primary" onClick={handleClearFilter} disabled={!filterValues?.periodTime}>
          {t('alarms.filter.clear')}
        </Button>
      </Stack>
      <DataTable hover {...props} />
    </Stack>
  );
};

export default React.memo(MessageHistoryOverview);
