import React, { useState } from 'react';
import { Formik } from 'formik';
import T from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Button, FormGroup, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormElements, NotifyIcon, TimezoneGMT, TIMEZONES_GMT_NAMES } from 'web-components';
import { toPng } from 'html-to-image';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyledDescription, StyledDialog, StyledDialogBody, StyledDialogHeader } from './ReportExportDialog.Styled';
import { COLOR_PRIMARY } from '../../attrs/colors';

import {
  StyledDialogActions,
  StyledDialogSubHeader
} from '../MachinesOverviewDetails/EditMachineDialog/EditMachineDialog.Styled';

import { getShowReportExportModal } from '../../redux/batch_export/selectors';
import StyledButton from '../elements/StyledLastUpdated';
import { putRequesBatchReportExport, showBatchReportExportModal } from '../../redux/batch_export/actions';
import { initialReportExportValues } from '../../redux/batch_export/constants';
import { getUserInfo } from '../../redux/user/selectors';
import { getFormikError, getFormikHelperText, getUserFeature } from '../../helpers/utils';
import TimeInputList from '../TimeInputList';
import { FileFormatsBatches } from '../../helpers/consts';

const ReportExportDialog = ({ batchId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { user } = useSelector(getUserInfo);

  const { isOpen } = useSelector(getShowReportExportModal);

  const [isLoading, setLoading] = useState(false);

  const initialValues = { ...initialReportExportValues };

  const FILTERED_OUT_TIMEZONES = [TIMEZONES_GMT_NAMES.UNKNOWN];

  const timezones = TimezoneGMT.filter(({ value }) => !FILTERED_OUT_TIMEZONES.includes(value));

  const periods = [
    { key: '5s', value: '5 s' },
    { key: '30s', value: '30 s' },
    { key: '1m0s', value: '1 min' },
    { key: '1h0m0s', value: '1 h' },
    { key: '24h0m0s', value: '1 d' }
  ];

  const resizeGraph = async (height, width) => {
    const node = document.getElementById('graph-integration');
    if (!node || !node.style) {
      return;
    }
    node.style.maxHeight = height;
    node.style.maxWidth = width;
    node.style.minHeight = height;
    node.style.minWidth = width;
    await new Promise(resolve => setTimeout(resolve, 1000));
  };

  const graphRecorder = async () => {
    try {
      const node = document.getElementById('graph-integration');
      return await toPng(node);
    } catch (error) {
      return null;
    }
  };

  const normalizeValuesForActions = async values => {
    const { action } = values;
    const updateValue = values;

    if (action === 'csv') {
      updateValue.graphs = null;

      return updateValue;
    }

    await resizeGraph('540px', '1000px');
    const graphs = await graphRecorder();
    await resizeGraph(null, null);

    if (!graphs) {
      updateValue.graphs = null;

      return updateValue;
    }

    updateValue.graphs = [
      {
        name: 'Graph 1',
        image: graphs
      }
    ];

    return updateValue;
  };

  const handleSubmitForm = async values => {
    setLoading(true);
    const normalizedValue = await normalizeValuesForActions(values);

    dispatch(putRequesBatchReportExport(batchId, normalizedValue));
    setTimeout(() => setLoading(false), 1000);
  };

  const handleOpenReportExportModal = open => {
    dispatch(showBatchReportExportModal(open));
  };

  const handleClose = () => () => {
    handleOpenReportExportModal(false);
  };

  return (getUserFeature(user.data, 'BATCH_RECORDS') || { enabled: false }).enabled ? (
    <>
      <StyledButton
        variant="contained"
        color="primary"
        startIcon={<NotifyIcon iconName="export" />}
        data-testid="batach-record-export-button"
        onClick={() => handleOpenReportExportModal(true)}
      >
        {t('batch_records.export_report')}
      </StyledButton>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Formik
          initialValues={initialValues}
          onSubmit={values => {
            handleSubmitForm(values);
          }}
          enableReinitialize={false}
        >
          {({ values, handleSubmit, errors, setFieldValue, handleBlur, touched, handleChange }) => {
            const getError = getFormikError({ errors, touched });
            const getHelperText = getFormikHelperText({ errors, t });

            return (
              <StyledDialog open={isOpen}>
                <StyledDialogHeader
                  theme={theme}
                  highlightColor={COLOR_PRIMARY}
                  closeButtonProps={{ color: 'primary' }}
                  onClose={() => handleOpenReportExportModal(false)}
                >
                  {t('dialog.batch_record.title')}
                </StyledDialogHeader>
                <StyledDialogSubHeader theme={theme} withDivider={false} narrow>
                  {t('dialog.batch_record.subtitle')}
                </StyledDialogSubHeader>
                <StyledDialogBody>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormGroup row>
                        <FormElements.Select
                          error={getError('action')}
                          helperText={getHelperText('action')}
                          handleChange={handleChange}
                          id="action"
                          label={t('dialog.batch_record.select_file_format')}
                          name="action"
                          onBlur={handleBlur}
                          value={values.action}
                          fullWidth
                        >
                          {FileFormatsBatches.map(file => (
                            <option key={file.key} value={file.value}>
                              {file.name}
                            </option>
                          ))}
                        </FormElements.Select>
                      </FormGroup>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <FormGroup row>
                        <TimeInputList
                          notTime
                          handleTimeChange={value => setFieldValue('window', value)}
                          value={values.window}
                          labelText="dialog.batch_record.sample_period"
                          fieldId="sample_period"
                          autoComplete={false}
                          options={periods}
                          onBlur={handleBlur}
                          handleBlur={handleBlur}
                          errors={errors}
                          error={errors.time_from && touched.time_from}
                          helperText={errors.time_from && touched.time_from && errors.time_from}
                          touched={touched}
                          forceValue
                          getError={getError}
                          getHelperText={getHelperText}
                        />
                        <StyledDescription>{t('dialog.batch_record.period_description')}</StyledDescription>
                      </FormGroup>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormGroup row>
                      <FormElements.Select
                        error={getError('timeZone')}
                        helperText={getHelperText('timeZone')}
                        handleChange={handleChange}
                        id="timeZone"
                        label={t('dialog.data_exports.timezone')}
                        name="timeZone"
                        onBlur={handleBlur}
                        value={values.timeZone}
                      >
                        {timezones.map(gmt => (
                          <option key={gmt.value} value={gmt.value}>
                            {gmt.name}
                          </option>
                        ))}
                      </FormElements.Select>
                    </FormGroup>
                  </Grid>
                </StyledDialogBody>

                <StyledDialogActions>
                  <Button variant="text" color="secondary" onClick={handleClose()}>
                    {t('dashboard.custom.cancel')}
                  </Button>
                  <FormElements.LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={Object.keys(errors).length !== 0 || isLoading}
                    loading={isLoading}
                  >
                    {t('dialog.batch_record.export')}
                  </FormElements.LoadingButton>
                </StyledDialogActions>
              </StyledDialog>
            );
          }}
        </Formik>
        {}
      </LocalizationProvider>
    </>
  ) : (
    <></>
  );
};

ReportExportDialog.propTypes = {
  batchId: T.string.isRequired
};

export default React.memo(ReportExportDialog);
