import React from 'react';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';

import MachineLegendTooltip from 'components/MachineLegendTooltip';
import { COLOR_PRIMARY } from '../../../attrs/colors';

import {
  HeadingWrapper,
  StyledCaption,
  StyledCheckbox,
  StyledEmptyState,
  StyledPaper,
  StyledSensorComparisonFooter
} from './SensorComparisonBlock.styled';
import { useSensorComparisonData } from './sensorComparisonBlock.hooks';
import SensorComparisonGraph from './SensorComparisonGraph';
import MobileSensorPortrait from '../../elements/MobileSensorPortrait';

const SensorComparisonBlock = ({ mobile }) => {
  const { t } = useTranslation();

  const { sensors, loadingState, selectedSensors, setSelectedSensors, shouldShowMachineState, handleCheckboxClick } =
    useSensorComparisonData();
  const shouldShowGraph = Boolean(selectedSensors.length);
  return (
    <StyledPaper elevation={3}>
      <HeadingWrapper>
        <StyledCaption>{t(`batch_records.details.sensor_comparison`)}</StyledCaption>
        {shouldShowGraph && <MachineLegendTooltip />}
        {shouldShowGraph && (
          <StyledCheckbox
            checked={shouldShowMachineState}
            label={t('batch_records.details.show_machine_state_changes')}
            onChange={handleCheckboxClick}
            activeColor={COLOR_PRIMARY}
          />
        )}
      </HeadingWrapper>
      {shouldShowGraph && (
        <div id="graph-integration">
          {mobile ? <MobileSensorPortrait /> : <SensorComparisonGraph loadingState={loadingState} />}
        </div>
      )}
      {!shouldShowGraph && <StyledEmptyState type="batch_sensors_comparison_no_sensor_selected" />}
      <StyledSensorComparisonFooter
        sensors={sensors}
        selectedSensors={selectedSensors}
        onChange={setSelectedSensors}
        mobile={mobile}
      />
    </StyledPaper>
  );
};

SensorComparisonBlock.propTypes = {
  mobile: T.bool
};

SensorComparisonBlock.defaultProps = {
  mobile: false
};

export default SensorComparisonBlock;
