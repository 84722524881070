import React from 'react';
import { IconButton } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { NotifyIcon, useResolutionCheck } from 'web-components';

import T from 'prop-types';
import { NAVIGATION_BAR_LOGO_HEIGHT } from '../../attrs/layout';
import Logo from '../../attrs/logo';

// On mobile resolutions:
// all routes that are on a sub navigation level need to show a back button instead logo

const generateLinkFromCrumbs = crumbs => `/${crumbs.join('/')}`;

const LogoLink = ({ hasBack }) => {
  const { pathname } = useLocation();
  const crumbs = pathname
    .split('/')
    .filter(item => item !== '')
    .slice(0, -1);
  const { isMobile } = useResolutionCheck();

  return (
    <>
      {isMobile && crumbs.length >= 1 && hasBack ? (
        <IconButton
          aria-label="back"
          color="inherit"
          component={Link}
          to={generateLinkFromCrumbs(crumbs)}
          style={{ marginRight: '1.8rem' }}
        >
          <NotifyIcon iconName="backArrow" />
        </IconButton>
      ) : (
        <Link to="/dashboard" data-testid="dashboard-navbar-link">
          <Logo height={`${NAVIGATION_BAR_LOGO_HEIGHT}px`} style={{ minWidth: '70px' }} />
        </Link>
      )}
    </>
  );
};

LogoLink.defaultProps = {
  hasBack: true
};

LogoLink.propTypes = {
  hasBack: T.bool
};

export default LogoLink;
