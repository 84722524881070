import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Container } from '@mui/material';
import { FormElements } from 'web-components';
import { getMachineOptions, getProductionLinesOptions } from 'redux/support/selectors';
import { submitSalesforceTicket } from 'redux/support/actions';
import FormikEffect from 'components/forms/FormikEffect';
import { SalesforceSchema } from 'attrs/formValidation';
import MachineStoppedRadioGroup from './MachineStoppedRadioGroup';
import { COLOR_PRIMARY } from '../../../attrs/colors';

import {
  SupportFormWrapper,
  FormDetailsWrapper,
  MandatoryFieldsLabel,
  StyledInputAdornment
} from './SupportForm.styled';

const SupportForm = ({ onFormChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productionLines = useSelector(getProductionLinesOptions);
  const machines = useSelector(getMachineOptions);

  const handleFormSubmit = values => {
    dispatch(submitSalesforceTicket(values));
  };

  const productionLinesOptions = productionLines.map(prodLine => (
    <option key={prodLine} value={prodLine}>
      {prodLine}
    </option>
  ));

  const machinesOptions = selectedProductionLine =>
    machines
      .filter(({ prodLine }) => prodLine === selectedProductionLine)
      .map(({ value, label }) => (
        <option key={`machine-${value}`} value={value}>
          {label}
        </option>
      ));

  return (
    <Formik
      initialValues={{
        productionRelated: false,
        productionLine: '',
        machine: '',
        machineStopped: true,
        subject: '',
        description: ''
      }}
      onSubmit={values => handleFormSubmit(values)}
      validationSchema={SalesforceSchema}
      enableReinitialize
    >
      {({ values, handleBlur, handleChange, setFieldValue, touched, errors }) => (
        <SupportFormWrapper>
          <FormDetailsWrapper>
            <Container maxWidth="sm" style={{ marginTop: '0.875rem' }}>
              <FormikEffect onChange={onFormChange} />
              <FormElements.Checkbox
                id="productionRelated"
                data-testid="production-blocked-checkbox"
                checked={values.productionRelated}
                label={t('support.salesforce.production_blocked')}
                onChange={handleChange}
                activeColor={COLOR_PRIMARY}
              />
              <FormElements.Select
                className="productionLine"
                value={values.productionLine}
                fullWidth
                handleChange={handleChange}
                onBlur={handleBlur}
                label={t('support.salesforce.production_line')}
                name="productionLine"
                id="productionLine"
                size="small"
                disabled={values.productionRelated}
                required
                withEmptyField
                error={errors.productionLine && touched.productionLine}
                helperText={
                  errors.productionLine && touched.productionLine && t(`form.validate.${errors.productionLine}`)
                }
              >
                {productionLinesOptions}
              </FormElements.Select>
              {!values.productionRelated && values.productionLine && (
                <>
                  <FormElements.Select
                    className="machine"
                    value={values.machine}
                    fullWidth
                    handleChange={handleChange}
                    onBlur={handleBlur}
                    label={t('support.salesforce.machine')}
                    name="machine"
                    id="machine"
                    size="small"
                    withEmptyField
                  >
                    {machinesOptions(values.productionLine)}
                  </FormElements.Select>

                  <MachineStoppedRadioGroup machineStopped={values.machineStopped} setFieldValue={setFieldValue} />
                </>
              )}
              <FormElements.TextField
                className="subject"
                style={{
                  marginTop: !values.productionRelated && values.productionLine ? '0' : '3.25rem'
                }}
                label={t('support.salesforce.subject')}
                fullWidth
                id="subject"
                margin="normal"
                name="subject"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.subject}
                InputProps={{
                  endAdornment: <StyledInputAdornment position="end">{values.subject.length}/80</StyledInputAdornment>,
                  inputProps: { maxLength: 80 }
                }}
                required
                error={errors.subject && touched.subject}
                helperText={errors.subject && touched.subject && t(`form.validate.${errors.subject}`)}
              />
              <FormElements.TextField
                className="description"
                label={t('support.salesforce.description')}
                fullWidth
                id="description"
                margin="normal"
                name="description"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                multiline
                rows={3}
              />
            </Container>
          </FormDetailsWrapper>
          <Container maxWidth="sm">
            <MandatoryFieldsLabel>{t('support.salesforce.mandatory_fields')}</MandatoryFieldsLabel>
          </Container>
        </SupportFormWrapper>
      )}
    </Formik>
  );
};

SupportForm.propTypes = {
  onFormChange: T.func.isRequired
};

export default SupportForm;
