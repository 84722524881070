import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, Paper } from '@mui/material';

import { NotifyIcon, MachineIcon } from 'web-components';

import { getSensorProps } from 'helpers/utils';
import {
  StyledMenuList,
  StyledListSubheader,
  MachineIconWrapper,
  StyledMenuItem,
  StyledPopper
} from './SensorSelector.styled';

const SensorSelector = ({ anchorElement, selectedSensorType, selectedSensorTypes, sensors, onItemSelect, onClose }) => {
  const { t } = useTranslation();

  const isOpenend = Boolean(anchorElement);
  const width = isOpenend ? anchorElement.getBoundingClientRect().width : 0;

  const handleMenuItemClick = (event, type) => {
    event.stopPropagation();
    onClose();
    onItemSelect(type);
  };

  /* eslint-disable camelcase */
  const sensorsList = sensors.map(({ type, status, name, mapValue, is_custom, custom_icon_type }) => {
    const isCurrentlySelected = type === selectedSensorType;
    const isSelectedInToolbar = selectedSensorTypes.includes(type);
    const machineIconProps = {
      iconName: getSensorProps(!is_custom ? mapValue : custom_icon_type).icon,
      status,
      fontSize: 'large',
      style: {
        marginRight: '1rem'
      },
      viewBox: '0 0 32 32'
    };

    return (
      <StyledMenuItem
        key={type}
        disabled={isSelectedInToolbar}
        selected={isCurrentlySelected}
        onClick={event => handleMenuItemClick(event, type, selectedSensorType)}
        data-testid="sensor-selector-sensor-list-item"
      >
        <MachineIconWrapper>
          <MachineIcon {...machineIconProps} />
          {name}
        </MachineIconWrapper>
        {isCurrentlySelected && <NotifyIcon color="primary" iconName="checkmark" />}
      </StyledMenuItem>
    );
  });

  return (
    <StyledPopper
      open={isOpenend}
      anchorEl={anchorElement}
      role={undefined}
      disablePortal
      placement="top"
      onClose={onClose}
    >
      <Paper elevation={2} style={{ width }}>
        <ClickAwayListener onClickAway={onClose}>
          <StyledMenuList autoFocusItem={isOpenend}>
            <StyledListSubheader>{t('general.graphs.select_sensor')}</StyledListSubheader>
            {sensorsList}
          </StyledMenuList>
        </ClickAwayListener>
      </Paper>
    </StyledPopper>
  );
};

SensorSelector.propTypes = {
  anchorElement: T.shape({
    getBoundingClientRect: T.func.isRequired
  }),
  selectedSensorType: T.string,
  selectedSensorTypes: T.arrayOf(T.string).isRequired,
  sensors: T.arrayOf(T.shape({})).isRequired,
  onItemSelect: T.func.isRequired,
  onClose: T.func.isRequired
};

SensorSelector.defaultProps = {
  anchorElement: null,
  selectedSensorType: null
};

export default SensorSelector;
