import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MachineIcon, NotifyIcon, NotifyTypo } from 'web-components';

import { getMachineStatusProps } from '../../../../helpers/utils';
import { getSelectedMachineNotificationRule } from '../../../../redux/machines/selectors';
import * as S from './elements';
import MachineErrorChip from '../../../../components/MachineErrorChip';

const { SensorCardValue, MachineText } = NotifyTypo;
const CurrentValue = ({ machine, unresolvedError }) => {
  const status = getMachineStatusProps(((machine || {}).status || {}).value);
  const isWarning = ((machine || {}).status || {}).warning || false;
  const error = ((machine || {}).status || {}).error || {};
  const notificationRules = useSelector(getSelectedMachineNotificationRule);

  const { t } = useTranslation();

  const hasNotificationRule =
    notificationRules.length > 0 ? t('dashboard.sensors.enabled') : t('dashboard.sensors.disabled');

  const showChipError = () => {
    if (machine?.status?.value === 'CRITICAL' && (error.code || error.message)) {
      return (
        <div style={{ marginTop: '.5rem', fontSize: 14 }}>
          <MachineErrorChip error={error} />
        </div>
      );
    }
    if (machine?.status?.value === 'CRITICAL' && (unresolvedError?.code || unresolvedError?.message)) {
      return (
        <div style={{ marginTop: '.5rem', fontSize: 14 }}>
          <MachineErrorChip error={unresolvedError} />
        </div>
      );
    }

    return null;
  };

  return (
    <S.ContainerCurrentStatus>
      <S.StatusMachineWrapper>
        <S.HeadingCurrentStatus>Status</S.HeadingCurrentStatus>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <MachineIcon
            iconName={status.icon || 'error'}
            fontSize="large"
            viewBox="0 0 32 32"
            style={{ width: 30, height: 20 }}
          />
          <MachineText
            style={{
              marginRight: 5,
              fontSize: 14,
              fontWeight: '400'
            }}
          >
            {t(`machines.status.${status.value.toLowerCase()}`)}
          </MachineText>
          {isWarning && <NotifyIcon iconName="warning" viewBox="0 0 20 20" style={{ marginLeft: 5 }} />}
        </div>
        {showChipError()}
      </S.StatusMachineWrapper>
      <S.StatusNotificationWrapper>
        <S.HeadingCurrentStatus>{t('dashboard.sensors.notification_set_up')}</S.HeadingCurrentStatus>
        {hasNotificationRule && (
          <SensorCardValue style={{ fontSize: 14, fontWeight: '400' }}>{hasNotificationRule}</SensorCardValue>
        )}
      </S.StatusNotificationWrapper>
    </S.ContainerCurrentStatus>
  );
};
CurrentValue.propTypes = {
  machine: T.shape({ name: T.string, id: T.string, status: T.shape({ value: T.string, warning: T.bool }) }),
  unresolvedError: T.instanceOf(Object)
};
CurrentValue.defaultProps = {
  machine: null,
  unresolvedError: {}
};

export default CurrentValue;
