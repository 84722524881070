import typeToReducer from 'type-to-reducer';
import { combineReducers } from 'redux';
import { loadingStateReducer } from '../utils';

import { LOAD_ALARMS, LOAD_ALARMS_WITH_FILTER } from './constants';

export default combineReducers({
  loadingState: loadingStateReducer([LOAD_ALARMS]),
  isLoadingAlarmsWithFilter: loadingStateReducer([LOAD_ALARMS_WITH_FILTER]),
  data: typeToReducer(
    {
      [LOAD_ALARMS]: {
        SUCCESS: (state, { payload }) => payload
      },
      [LOAD_ALARMS_WITH_FILTER]: {
        SUCCESS: (data, { payload }) => payload
      }
    },
    {}
  )
});
