import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Button, Container } from '@mui/material';
import { FormElements } from 'web-components';
import { Formik } from 'formik';
import T from 'prop-types';
import { COLOR_PRIMARY } from '../../../../attrs/colors';
import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogBody,
  StyledDialogHeader,
  StyledDialogSubHeader
} from './CreateBatchNoteDialog.Styled';
import { BatchNoteFormSchema } from '../../../../attrs/formValidation';
import { getFormikError, getFormikHelperText } from '../../../../helpers/utils';
import { BatchNoteResponse } from '../../../../helpers/propTypes';

const CreateBatchNoteDialog = ({ batchNote, isOpen, handleCLose, handleFormSubmit }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Formik
      initialValues={batchNote}
      onSubmit={values => {
        handleFormSubmit(values);
      }}
      validationSchema={BatchNoteFormSchema}
      enableReinitialize
    >
      {({ values, handleSubmit, touched, errors, handleChange }) => (
        <StyledDialog open={isOpen}>
          <StyledDialogHeader theme={theme} highlightColor={COLOR_PRIMARY} onClose={handleCLose(values)} narrow>
            {t('batch_records.batch_notes.add_a_note')}
          </StyledDialogHeader>
          <StyledDialogSubHeader theme={theme} withDivider={false} narrow>
            {t('batch_records.batch_notes.add_a_note_tip')}
          </StyledDialogSubHeader>
          <StyledDialogBody>
            <Container>
              <FormElements.TextField
                className="subject"
                label={t('batch_records.batch_notes.fields.title_tag')}
                id="title"
                margin="normal"
                name="title"
                fullWidth
                required
                error={getFormikError({ errors, touched })('title')}
                helperText={getFormikHelperText({ errors, t })('title')}
                onChange={handleChange}
                value={values.title}
                data-testid="batch-note-modal-title-field"
              />
              <FormElements.TextField
                className="subject"
                label={t('batch_records.batch_notes.fields.description_tag')}
                id="note"
                margin="normal"
                name="note"
                multiline
                rows={6}
                required
                fullWidth
                error={getFormikError({ errors, touched })('note')}
                helperText={getFormikHelperText({ errors, t })('note')}
                onChange={handleChange}
                value={values.note}
                data-testid="batch-note-modal-note-field"
              />
            </Container>
          </StyledDialogBody>
          <StyledDialogActions>
            <Button variant="text" color="secondary" onClick={handleCLose(values)}>
              {t('dashboard.custom.cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              type="submit"
              data-testid="batch-note-modal-save-button"
            >
              {t('dashboard.custom.save')}
            </Button>
          </StyledDialogActions>
        </StyledDialog>
      )}
    </Formik>
  );
};

CreateBatchNoteDialog.propTypes = {
  batchNote: T.shape(BatchNoteResponse),
  isOpen: T.bool.isRequired,
  handleCLose: T.func.isRequired,
  handleFormSubmit: T.func.isRequired
};

CreateBatchNoteDialog.defaultProps = {
  batchNote: { id: '', title: '', note: '' }
};

export default CreateBatchNoteDialog;
