import React from 'react';
import moment from 'moment';

// Maintained the previous logic that apparently works
const COLUMN_ALARM_ACTIVATION_TIME = 'time';
const COLUMN_ALARM_ACKNOWLEDGE_TIME = 'resolved';
const COLUMN_ALARM_MESSAGE = 'message';
const COLUMN_ALERT_ACTIVATION_TIME = 'created_at';
const COLUMN_ALERT_ACKNOWLEDGE_TIME = 'deleted_at';
const COLUMN_ALERT_MESSAGE = 'error_message';

const formatDate = dateValue => moment(dateValue, 'YYYY-MM-DD[T]HH:mm:ssZ').format('DD MMM YYYY, hh:mm A');

export const generateAlarmColumns = t => [
  {
    id: COLUMN_ALARM_ACTIVATION_TIME,
    label: t(`alarms.overview.activation_time`),
    sortable: true,
    cellRenderer: row => <div>{formatDate(row.time)}</div>
  },
  {
    id: COLUMN_ALARM_ACKNOWLEDGE_TIME,
    label: t(`alarms.overview.acknowledge_time`),
    sortable: true,
    cellRenderer: row => <div>{row.resolved ? formatDate(row.resolved) : '-'}</div>
  },
  {
    id: COLUMN_ALARM_MESSAGE,
    label: t(`alarms.overview.alarm_message`),
    sortable: false,
    cellRenderer: row => (
      <div style={{ width: '450px' }}>
        {row.is_custom ? row.message : `${row.code}: ${t(`dashboard.machines.error_codes.${row.code}`)}`}
      </div>
    )
  }
];

export const generateAlertColumns = t => [
  {
    id: COLUMN_ALERT_ACTIVATION_TIME,
    label: t(`alerts.overview.activation_time`),
    sortable: true,
    cellRenderer: row => <div>{formatDate(row.created_at)}</div>
  },
  {
    id: COLUMN_ALERT_ACKNOWLEDGE_TIME,
    label: t(`alerts.overview.acknowledge_time`),
    sortable: true,
    cellRenderer: row => <div>{row.deleted_at ? formatDate(row.deleted_at) : '-'}</div>
  },
  {
    id: COLUMN_ALERT_MESSAGE,
    label: t(`alerts.overview.alert_message`),
    sortable: false,
    cellRenderer: row => <div style={{ width: '550px' }}>{row.error_message.trim() ? row.error_message : '-'}</div>
  }
];
