/**
 * Sorts an array of objects based on a specified column and order.
 *
 * @template T - The type of objects in the array.
 * @template K - The keys of the object, used as the column identifier.
 *
 * @param {T[]} rows - The array of objects to be sorted.
 * @param {K} colId - The key of the object that should be used for sorting.
 * @param {string} order - The sorting order, either 'asc' (ascending) or 'desc' (descending)
 * The type of order was made {string} instead of {'asc' | 'desc'} to avoid cascade changes.
 *
 * @returns {T[]} - A new sorted array based on the given column and order.
 */
export const sortFn = <T, K extends keyof T>(rows: T[], colId: K, order: string): T[] =>
  [...rows].sort((a, b) => {
    const valueA = a[colId];
    const valueB = b[colId];

    if (valueA == null || valueB == null || valueA === valueB) return 0;

    const valA = typeof valueA === 'string' ? valueA.toLowerCase() : valueA;
    const valB = typeof valueB === 'string' ? valueB.toLowerCase() : valueB;

    if (order.toLocaleLowerCase() === 'asc') {
      return valA > valB ? 1 : -1;
    }
    return valA < valB ? 1 : -1;
  });
