import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ResponsiveIconButton, NotifyTypo } from 'web-components';
import { Divider, Paper, Stack, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';

import { getOnlyNotifyRole } from 'helpers/role';
import Breadcrumbs from '../../../../components/elements/Breadcrumbs';
import withLoadingState from '../../../../helpers/withLoadingState';
import { getUserRole } from '../../../../redux/user/selectors';
import PERMISSIONS from '../../../../attrs/permissions';
import SensorNotificationSettings from '../../../../components/forms/Machines/SensorNotificationSettings';

import CurrentValue from './CurrentValue';
import { getSelectedSensor, getSelectedMachine } from '../../../../redux/machines/selectors';
import { getData } from '../../../../redux/rootSelectors';
import { loadMachine, loadSelectedSensorHistory } from '../../../../redux/machines/actions';

import { SensorContentWrapper, PageHeader, PageTitle, PageHeaderTitle } from './elements';
import { getCustomSensorId, getSensorName, getSensorPropsFromType, isCustomSensor } from '../../../../helpers/utils';
import { getCustomSensorSelector } from '../../../../redux/custom_sensor/selectors';
import { GetCustomSensorById } from '../../../../redux/custom_sensor/actions';
import SensorChart from './SensorChart';

const SensorHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const role = useSelector(getUserRole);
  const [permissions, setPermissions] = useState();
  const [chartKey, setChartKey] = useState(Date.now());
  const isMobile = useMediaQuery('(max-width:425px) and (orientation:portrait)');

  const { id: machineId } = useParams();
  const metricHistory = useSelector(compose(getData, getSelectedSensor));

  const { customSensor } = useSelector(getCustomSensorSelector);
  const machine = useSelector(compose(getData, getSelectedMachine));

  const { loadingState: wsLoadingState } = useSelector(state => state.stream);

  useEffect(() => {
    if (isCustomSensor(metricHistory.sensor.type)) {
      metricHistory.name = '';
      dispatch(GetCustomSensorById(getCustomSensorId(metricHistory.sensor.type)));
    }
    dispatch(loadMachine(machineId));
    setChartKey(Date.now());
  }, [dispatch, metricHistory, wsLoadingState, machineId]);

  useEffect(() => {
    if (role) {
      const oneRole = getOnlyNotifyRole(role);
      setPermissions(PERMISSIONS.dashboard[oneRole]);
    }
  }, [role]);

  if (isCustomSensor(metricHistory.sensor.type)) {
    metricHistory.name = customSensor.name;
    metricHistory.unit = customSensor.unit;
    metricHistory.period = `${customSensor.period_sec}s`;
    metricHistory.mapValue = customSensor.custom_icon_type;
  }

  const reloadSensorHistory = () => {
    dispatch(loadSelectedSensorHistory(machineId, metricHistory.sensor.type));
    setChartKey(Date.now());
  };

  const sensorDetails = getSensorPropsFromType(metricHistory.sensor.type);
  const breadcrumbObj = [
    { route: '/dashboard', name: `${t('dashboard.title')}` },
    { route: `/dashboard/${machineId}`, name: `${machine.name}` },
    { route: null, name: `${getSensorName(metricHistory.sensor, t(sensorDetails.name))}` }
  ];

  return (
    <>
      <PageHeader alwaysVisible>
        <Breadcrumbs data={breadcrumbObj} />
        <PageTitle>
          <PageHeaderTitle>
            {`${t('dashboard.sensors.sensor_overview')} - ${getSensorName(
              metricHistory.sensor,
              t(sensorDetails.name)
            )}`}
          </PageHeaderTitle>
        </PageTitle>
      </PageHeader>

      <SensorContentWrapper maxWidth={false}>
        <Stack spacing={3}>
          <Paper
            sx={{
              overflow: 'hidden',
              width: '100%',
              p: { xs: 1, sm: 2, md: 2, lg: 3 }
            }}
            data-testid="chart-template-details"
          >
            <Stack spacing={1}>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'flex-start'
                }}
              >
                <NotifyTypo.Heading2 style={{ fontSize: 14 }}>
                  {t('dashboard.sensors.current_status')}
                </NotifyTypo.Heading2>
                {isMobile ? (
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <ResponsiveIconButton
                      buttonText={t('dashboard.sensors.refresh')}
                      color="primary"
                      iconName="reload"
                      variant="text"
                      onClick={reloadSensorHistory}
                    />
                    {metricHistory.sensor.trigger && permissions && permissions.canEditSensorNotifications && (
                      <>
                        <Divider orientation="vertical" flexItem />
                        <SensorNotificationSettings
                          sensor={metricHistory.sensor}
                          machineId={machineId}
                          isMobile={isMobile}
                        />
                      </>
                    )}
                  </Stack>
                ) : (
                  <>
                    {metricHistory.sensor.trigger && permissions && permissions.canEditSensorNotifications && (
                      <SensorNotificationSettings
                        sensor={metricHistory.sensor}
                        machineId={machineId}
                        isMobile={isMobile}
                      />
                    )}
                  </>
                )}
              </Stack>
              <CurrentValue isMobile={isMobile} />
            </Stack>
          </Paper>
          <SensorChart key={chartKey} machineId={machineId} sensorParam={metricHistory.sensor} />
        </Stack>
      </SensorContentWrapper>
    </>
  );
};

export default withLoadingState(SensorHistory);
