import React, { useState } from 'react';
import { push } from 'connected-react-router';
import T from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Divider, MenuList } from '@mui/material';
import moment from 'moment';
import { MachineIcon, NotifyIcon, NotifyTypo } from 'web-components';
import { orderBy } from 'lodash';

import { NotificationFooter, NotificationHeading, StyledEmptyState, StyledMenuItem } from './elements';
import withLoadingState from '../../../helpers/withLoadingState';
import StyledChip from '../../elements/StyledChip';
import { NOTIFICATION_LIST_DEFAULT_AMOUNT, NOTIFICATION_LIST_STEP_AMOUNT } from '../../../attrs/layout';
import { markAlertAsRead, markAllAlertsAsRead } from '../../../redux/alerts/actions';
import { getAlertUrl, getMachineIconName, makeErrorAlertTitle, makeSensorAlertTitle } from '../../../helpers/alerts';

const { Heading1, Heading2, InfoText } = NotifyTypo;

const renderAlertError = (alert, t) => {
  const hasError = ((alert || {}).error || {}).code || null;

  return (
    <>
      <MachineIcon iconName={getMachineIconName(alert)} style={{ marginRight: '1rem' }} viewBox="0 0 32 32" />
      <div>
        <Heading2 gutterBottom>{makeErrorAlertTitle(t, alert)}</Heading2>
        {hasError && (
          <StyledChip
            className="error"
            label={`${alert.error.code}: ${t(`dashboard.machines.error_codes.${alert.error.code}`)}`}
            size="small"
            style={{ margin: '.5rem 0' }}
          />
        )}
        <InfoText>{moment(alert.time).fromNow()}</InfoText>
      </div>
    </>
  );
};

const renderAlertMetrics = (alert, t) => (
  <>
    <MachineIcon
      iconName={getMachineIconName(alert)}
      status={alert.severity}
      style={{ marginRight: '1rem' }}
      viewBox="0 0 32 32"
    />
    <div>
      <Heading2 gutterBottom>{makeSensorAlertTitle(t, alert)}</Heading2>
      <InfoText>{moment(alert.time).fromNow()}</InfoText>
    </div>
  </>
);

const Alerts = ({ alerts, mobile, setMenuOpen, menuOpen }) => {
  const dispatch = useDispatch();
  const [amountShown, setAmountShown] = useState(NOTIFICATION_LIST_DEFAULT_AMOUNT);
  const { t } = useTranslation();
  const alertsOrderedByTime = orderBy(alerts, item => item.time, 'desc');

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setMenuOpen(false);
    }
  }

  const handleMarkAllAlertsAsReadClick = () => {
    if (alerts.length) {
      dispatch(markAllAlertsAsRead(alerts));
    }
  };

  const makeAlertClickHandler = alert => () => {
    const url = getAlertUrl(alert);

    dispatch(markAlertAsRead(alert));

    dispatch(push(url));
    setMenuOpen(false);
  };

  const anyNewAlert = alerts.length > 0;

  const alertElements = alertsOrderedByTime.slice(0, amountShown).map(alert => (
    <StyledMenuItem
      key={`${alert.time}-${alert.machine_id}-${alert.metric}`}
      className={alert.unread && 'unread'}
      onClick={makeAlertClickHandler(alert)}
      data-testid="alerts-menu-item"
      data-test-unread={alert.unread}
      data-test-type={alert.metric ? 'metrics' : 'error'}
      data-test-machine-id={alert.machine_id}
      data-test-sensor-type={alert.metric || null}
    >
      <div>{alert.metric ? renderAlertMetrics(alert, t) : renderAlertError(alert, t)}</div>
      <NotifyIcon iconName="chevronRight" />
    </StyledMenuItem>
  ));

  const alertHeading = (
    <NotificationHeading key="heading">
      <Heading1>{t('alerts.title')}</Heading1>
      <Button
        data-testid="alerts-mark-all-as-read-button"
        color="primary"
        disabled={!anyNewAlert}
        onClick={handleMarkAllAlertsAsReadClick}
      >
        {t('alerts.mark_all_as_read')}
      </Button>
    </NotificationHeading>
  );

  const alertFooter =
    alertElements.length > 0 ? (
      <NotificationFooter key="footer">
        <Button
          color="primary"
          disabled={amountShown >= alertsOrderedByTime.length}
          onClick={() => setAmountShown(amountShown + NOTIFICATION_LIST_STEP_AMOUNT)}
        >
          {t('alerts.show_more')}
        </Button>
      </NotificationFooter>
    ) : (
      <div key="footer">
        <Divider />
        <StyledEmptyState align="center">{t('alerts.text_empty_list')}</StyledEmptyState>
      </div>
    );

  const menuListProps = mobile
    ? {}
    : {
        autoFocusItem: menuOpen,
        id: 'notification-list-grow',
        onKeyDown: handleListKeyDown,
        style: { maxHeight: 'calc(100vh - 100px)', overflow: 'auto' }
      };

  return (
    <MenuList data-testid="alerts-menu-list" {...menuListProps}>
      {[alertHeading, alertElements, alertFooter]}
    </MenuList>
  );
};

Alerts.propTypes = {
  mobile: T.bool,
  alerts: T.arrayOf(T.shape({})),
  setMenuOpen: T.func.isRequired,
  menuOpen: T.bool.isRequired
};

Alerts.defaultProps = {
  mobile: false,
  alerts: []
};

export default withLoadingState(Alerts);
