import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import { CircularProgress, Button } from '@mui/material';
import { Dialog, NotifyTypo, FormElements } from 'web-components';
import { deleteNotificationRule } from '../../../../redux/machines/actions';
import { COLOR_PRIMARY, PRIMARY_TEXT_COLOR } from '../../../../attrs/colors';
import { isLoading, isSuccess } from '../../../../helpers/utils';
import * as S from './styled';

const { InfoText } = NotifyTypo;

const RemoveNotificationRuleDialog = ({ notificationRule, notificationDeleteState, onSuccessfulDeletion }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isOpenDialog, setOpenDialog] = useState(false);

  const handleRemoveNotificationRuleClick = () => {
    dispatch(deleteNotificationRule(notificationRule.id));
  };

  const handleSuccessfulDeletion = () => {
    if (isSuccess(notificationDeleteState.status)) {
      onSuccessfulDeletion();
      setOpenDialog(false);
    }
  };

  useEffect(handleSuccessfulDeletion, [notificationDeleteState]);

  return (
    <>
      <S.ContainerMultiSelect>
        <InfoText style={{ color: PRIMARY_TEXT_COLOR }}>{t('dashboard.machines.remove_notify_rule.label')}</InfoText>
        <FormElements.ToggleButton
          data-testid="remove-notification-rule-button"
          checked={Boolean(notificationRule) !== false}
          disabled={Boolean(notificationRule) === false}
          onChange={() => setOpenDialog(true)}
        />
      </S.ContainerMultiSelect>

      <Dialog open={isOpenDialog} fullWidth={false}>
        <Dialog.Header highlightColor={COLOR_PRIMARY} onClose={() => setOpenDialog(false)}>
          {t('dashboard.machines.remove_notify_rule.title')}
        </Dialog.Header>
        <Dialog.Body>
          <div style={{ margin: '1rem 0' }}>
            <InfoText>{t('dashboard.machines.remove_notify_rule.text1')}</InfoText>
            <InfoText>{t('dashboard.machines.remove_notify_rule.text2')}</InfoText>
          </div>
        </Dialog.Body>
        <Dialog.Actions>
          <Button
            color="primary"
            disabled={isLoading(notificationDeleteState.status)}
            onClick={() => setOpenDialog(false)}
            type="button"
            variant="outlined"
          >
            {t('dashboard.machines.remove_notify_rule.keep_alert')}
          </Button>
          <Button
            color="secondary"
            data-testid="remove-notification-rule-submit"
            disabled={isLoading(notificationDeleteState.status)}
            endIcon={
              isLoading(notificationDeleteState.status) && (
                <CircularProgress color="inherit" size={14} style={{ marginLeft: '1rem' }} />
              )
            }
            onClick={handleRemoveNotificationRuleClick}
            type="submit"
            variant="contained"
          >
            {t('dashboard.machines.remove_notify_rule.remove_alert')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

RemoveNotificationRuleDialog.propTypes = {
  notificationRule: T.shape({
    id: T.string.isRequired
  }),
  notificationDeleteState: T.shape({
    status: T.string.isRequired
  }).isRequired,
  onSuccessfulDeletion: T.func.isRequired
};
RemoveNotificationRuleDialog.defaultProps = {
  notificationRule: null
};

export default RemoveNotificationRuleDialog;
