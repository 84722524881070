import T from 'prop-types';
import i18n from 'i18next';
import { BatchResponse } from 'helpers/propTypes';
import {
  BATCH_TARGET_TYPE_DECIMAL,
  BATCH_TARGET_TYPE_GRINDING_ENERGY,
  BATCH_TARGET_TYPE_NUMBER_CYCLES,
  BATCH_TARGET_TYPE_SPECIFIC_ENERGY,
  BATCH_TARGET_TYPE_TIME
} from 'attrs/batch';
import {
  getMachineType,
  getMillBeadMaterialType,
  getMillGrindingTechnology,
  getMillInnerLinerMaterial,
  getToFixedValue,
  isNullUndefinedOrEmpty,
  timeToString
} from 'helpers/utils';

const getData = data => {
  if (!data) {
    return null;
  }

  const batchStatusData = {
    localeKey: 'batch_status',
    rows: [
      {
        id: 'status-row1',
        row: [
          {
            localeKey: 'status',
            value: data.details.status
          },
          {
            localeKey: 'progress',
            value: Number.isInteger(data.details.progress)
              ? data.details.progress
              : getToFixedValue(data.details.progress, 2)
          },
          {
            localeKey: 'batch_length',
            value: timeToString(data.details.length)
          },
          {
            localeKey: 'total_batch_size',
            value: data.details.size || '--'
          },
          {
            localeKey: 'start_time',
            value: new Date(data.details.date.start).toLocaleString()
          },
          {
            localeKey: 'end_time',
            value: new Date(data.details.date.end).toLocaleString()
          }
        ]
      }
    ]
  };

  const invalidValue = value => [null, 'NONE', 'UNKNOWN', ''].includes(value);

  const getGrindingTarget = value =>
    invalidValue(value) ? '--' : i18n.t(`batch_records.target.${data.target.type.toLowerCase()}`);

  const getTarget = (type, value) => {
    if (invalidValue(value) || invalidValue(type)) {
      return '--';
    }

    if (type === BATCH_TARGET_TYPE_TIME) {
      return typeof value === 'string' ? value : timeToString(value);
    }

    return getToFixedValue(value, BATCH_TARGET_TYPE_DECIMAL[type]);
  };

  const batchDetailsData = {
    localeKey: 'batch_details',
    rows: [
      {
        id: 'details-recipe-temp',
        localeKey: 'recipe_temperature',
        row: [
          {
            localeKey: 'recipe',
            value: data.details.recipe || '--'
          },
          {
            localeKey: 'max_temp',
            value: getToFixedValue(data.details.max_allowed_temperature, 1)
          }
        ]
      },
      {
        id: 'details-target',
        localeKey: 'target',
        row: [
          {
            localeKey: 'grinding_target',
            value: getGrindingTarget(data.target.type)
          },
          {
            localeKey: 'target',
            value: getTarget(data.target.type, data.target.value)
          }
        ]
      },
      {
        id: 'details-energy',
        localeKey: 'grinding',
        row: [
          {
            localeKey: 'grinding_energy',
            value: getToFixedValue(
              data.property.grinding_energy,
              BATCH_TARGET_TYPE_DECIMAL[BATCH_TARGET_TYPE_GRINDING_ENERGY]
            )
          },
          {
            localeKey: 'specific_energy',
            value: getToFixedValue(
              data.property.specific_energy,
              BATCH_TARGET_TYPE_DECIMAL[BATCH_TARGET_TYPE_SPECIFIC_ENERGY]
            )
          },
          {
            localeKey: 'est_energy',
            value: getToFixedValue(
              data.property.est_energy_consumption,
              BATCH_TARGET_TYPE_DECIMAL[BATCH_TARGET_TYPE_GRINDING_ENERGY]
            )
          },
          {
            localeKey: 'total_grinding_time',
            value: data.property.total_grinding_time ? timeToString(data.property.total_grinding_time) : '--'
          },
          {
            localeKey: 'cycles',
            value: getToFixedValue(data.details.cycles, BATCH_TARGET_TYPE_DECIMAL[BATCH_TARGET_TYPE_NUMBER_CYCLES])
          }
        ]
      }
    ]
  };

  const machineType = getMachineType(data.machine.type);
  const grindingTechnology = getMillGrindingTechnology(data.machine.mechanical_config.grinding_technology);
  const innerLinerMaterial = getMillInnerLinerMaterial(data.machine.mechanical_config.inner_liner_material);
  const beadMaterialType = getMillBeadMaterialType(data.machine.mechanical_config.bead_material);

  let sambaSystemKey = 'batch_records.details.samba_system_false';
  if ([null, undefined].includes(data.machine.mechanical_config.samba_system)) {
    sambaSystemKey = 'batch_records.details.samba_system_undefined';
  } else if (data.machine.mechanical_config.samba_system === true) {
    sambaSystemKey = 'batch_records.details.samba_system_true';
  }

  const machineDetailsData = {
    localeKey: 'machine_details',
    rows: [
      {
        id: 'machine-row-1',
        row: [
          {
            localeKey: 'commission_no',
            value: data.machine.commission_number
          },
          {
            localeKey: 'name',
            value: data.machine.name || i18n.t('machines.overview.machine_name_not_assigned')
          },
          {
            localeKey: 'brand',
            value: machineType.brand
          },
          {
            localeKey: 'mill_system',
            value: machineType.type
          },
          {
            localeKey: 'grinding_technology',
            value: !isNullUndefinedOrEmpty(data.machine.mechanical_config.grinding_technology)
              ? grindingTechnology.name
              : '--'
          },
          {
            localeKey: 'inner_liner_material',
            value: !isNullUndefinedOrEmpty(data.machine.mechanical_config.inner_liner_material)
              ? innerLinerMaterial.name
              : '--'
          },
          {
            localeKey: 'samba_system',
            value: i18n.t(sambaSystemKey)
          },
          {
            localeKey: 'sieve_size',
            value: !isNullUndefinedOrEmpty(data.machine.mechanical_config.sieve_size)
              ? data.machine.mechanical_config.sieve_size
              : '--'
          }
        ]
      },
      {
        id: 'machine-beads',
        localeKey: 'beads',
        row: [
          {
            localeKey: 'bead_material',
            value: !isNullUndefinedOrEmpty(data.machine.mechanical_config.bead_material) ? beadMaterialType.name : '--'
          },
          {
            localeKey: 'bead_size_1',
            value: !isNullUndefinedOrEmpty(data.machine.mechanical_config.beads_size_1)
              ? data.machine.mechanical_config.beads_size_1
              : '--'
          },
          {
            localeKey: 'bead_size_2',
            value: !isNullUndefinedOrEmpty(data.machine.mechanical_config.beads_size_2)
              ? data.machine.mechanical_config.beads_size_2
              : '--'
          },
          {
            localeKey: 'bead_filling_level',
            value: !isNullUndefinedOrEmpty(data.machine.mechanical_config.beads_filling_level)
              ? data.machine.mechanical_config.beads_filling_level
              : '--'
          }
        ]
      }
    ]
  };

  return { batchStatusData, batchDetailsData, machineDetailsData };
};

getData.propTypes = {
  data: T.shape(BatchResponse).isRequired
};

export { getData };
