import { Box, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import { getMillSensor } from 'attrs/sensorType';
import { useTranslation } from 'react-i18next';
import { calculateValue, determineDirection, sideSpace } from 'helpers/chartUtils';
import { generateColor } from 'helpers/colorUtils';
import { getLineStyle } from 'attrs/chartsTemplate';
import MobileSensorPortrait from 'components/elements/MobileSensorPortrait';
import CarouselCardContainer from '../../CarouselCard/CarouselCardContainer';

/**
 * Returs static chart data based on provided index.
 *
 * @param {number} index - The index used to retrieve the predefined data.
 * @returns {number[]} - The predefined data corresponding to the index.
 * If the index is out of range, an empty array is returned.
 */
const generateDynamicData = index => {
  const predefinedData = [
    [96, 96, 96],
    [84, 84, 84],
    [72, 72, 72],
    [60, 60, 60],
    [48, 48, 48],
    [36, 36, 36],
    [24, 24, 24],
    [12, 12, 12]
  ];
  return predefinedData[index] || [];
};

export default function EditPropsChartTemplateDetails({ template, setEditingTemplate }) {
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const yAxisDefaultValue = [
    {
      data: []
    }
  ];
  const seriesDefaultValue = [
    {
      data: [],
      type: 'line'
    }
  ];
  const [yAxis, setYAxis] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const yAxisTmpList = [];
    const seriesTmpList = [];
    if (template?.sensors?.length > 0) {
      template.sensors.forEach((s, i) => {
        const millSensor = getMillSensor(s?.type);
        const sensorName = millSensor?.name || s?.name;
        const sensorUnit = millSensor?.unit || s?.custom_unit || s?.unit || 'n/a';
        const transLatedName = t(`${sensorName}`);

        const yAxisTmp = {
          min: 0,
          max: 100,
          type: 'value',
          name: sensorUnit,
          position: determineDirection(i),
          alignTicks: true,
          offset: calculateValue(i),
          axisLine: {
            show: true,
            lineStyle: {
              color: s?.line_color || generateColor(i)
            }
          },
          nameRotate: 45,
          nameTextStyle: {
            fontSize: 14,
            fontWeight: 'bold'
          },
          axisLabel: {
            show: true
          },
          splitLine: {
            show: false
          }
        };

        const seriesTmp = {
          name: transLatedName,
          type: 'line',
          color: s?.line_color || generateColor(i),
          lineStyle: {
            width: s?.line_thickness || 1,
            type: getLineStyle(s?.line_style)
          },
          yAxisIndex: i,
          data: generateDynamicData(i)
        };
        yAxisTmpList.push(yAxisTmp);
        seriesTmpList.push(seriesTmp);
      });
    }
    setYAxis(yAxisTmpList);
    setSeries(seriesTmpList);
  }, [template, template?.sensors]);

  const handleAddSensor = newSensors => {
    setEditingTemplate(prevState => ({
      ...prevState,
      sensors: [...prevState.sensors, ...newSensors]
    }));
  };

  const handleEditSensor = sensor => {
    setEditingTemplate(prevState => ({
      ...prevState,
      sensors: prevState.sensors.map(s =>
        (s?.temp_id ? s.temp_id === sensor.temp_id : s.id === sensor.id) ? sensor : s
      )
    }));
  };

  const handleDeleteSensor = sensorId => {
    setEditingTemplate(prevState => ({
      ...prevState,
      sensors: prevState.sensors.filter(s => (s?.temp_id || s.id) !== sensorId)
    }));
  };

  function getLeftPadding() {
    return sideSpace(template?.sensors?.length)[0];
  }

  function getRightPadding() {
    return sideSpace(template?.sensors?.length)[1];
  }

  function getOptions() {
    return {
      grid: {
        top: '80px',
        left: getLeftPadding(),
        right: getRightPadding()
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            onZero: false
          },
          data: ['0', '50', '100']
        }
      ],
      yAxis: yAxis.length > 0 ? yAxis : yAxisDefaultValue,
      series: series.length > 0 ? series : seriesDefaultValue
    };
  }

  return (
    <Paper
      sx={{
        overflow: 'hidden',
        width: '100%',
        p: 3
      }}
      data-testid="edit-props-chart-template-details"
    >
      <Stack>
        {isDownSm ? (
          <Box sx={{ pb: 4 }}>
            <MobileSensorPortrait />
          </Box>
        ) : (
          <ReactEcharts
            data-testid="chart-template-canvas"
            option={getOptions()}
            notMerge
            style={{
              minHeight: '580px',
              position: 'relative',
              overflow: 'hidden'
            }}
          />
        )}
        <CarouselCardContainer
          template={template}
          handleAddSensor={handleAddSensor}
          handleEditSensor={handleEditSensor}
          handleDeleteSensor={handleDeleteSensor}
        />
      </Stack>
    </Paper>
  );
}

EditPropsChartTemplateDetails.propTypes = {
  template: PropTypes.shape(),
  setEditingTemplate: PropTypes.func.isRequired
};

EditPropsChartTemplateDetails.defaultProps = {
  template: {}
};
