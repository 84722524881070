import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ContentWrapper } from '../../elements';
import withLoadingState from '../../helpers/withLoadingState';
import LastUpdated from '../../components/elements/LastUpdated';
import { ContainerTitle, PageHeader, PageHeaderTitle } from './elements';
import TooltipInfo from '../../components/TooltipInfo';

import { PaginationResponse } from '../../helpers/propTypes';

import StatusOverview from './StatusOverview';

const Dashboard = ({ machines, alerts }) => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader alwaysVisible>
        <PageHeader.Title>
          <ContainerTitle>
            <PageHeaderTitle>{t('dashboard.title')}</PageHeaderTitle>
            <TooltipInfo titleTooltip="dashboard.title_tooltip" />
          </ContainerTitle>
          {Object.keys(machines).length > 0 && <LastUpdated type="dashboard" align="left" />}
        </PageHeader.Title>
      </PageHeader>
      <ContentWrapper maxWidth={false}>
        <StatusOverview machines={machines} alerts={alerts} />
      </ContentWrapper>
    </>
  );
};

Dashboard.propTypes = {
  machines: T.shape({}),
  alerts: T.shape(PaginationResponse)
};

Dashboard.defaultProps = {
  machines: null,
  alerts: null
};

export default withLoadingState(Dashboard);
