import React, { useCallback, useEffect, useState } from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Step,
  Stepper
} from '@mui/material';

import { FormElements, NewDialog, NotifyIcon } from 'web-components';
import { getMillSensor } from 'attrs/sensorType';
import { generateColor } from 'helpers/colorUtils';
import { getData } from '../../../../../redux/rootSelectors';
import { createGraphsTemplate, loadGraphsTemplates } from '../../../../../redux/graphTemplates/actions';
import { getAllGraphTemplates } from '../../../../../redux/graphTemplates/selectors';
import { loadMachinesensorsbyid, loadMachinesSensorsTreeView } from '../../../../../redux/filters/actions';
import { getMachineSensorsTreeView, getSensors } from '../../../../../redux/filters/selectors';
import { isNullUndefinedOrEmpty } from '../../../../../helpers/utils';

import { CustomTreeView } from '../../../../../components/CustomTreeView';

import { CustomStepLabel, SectionTitle } from './CloneTemplate.Styled';

import { COLOR_PRIMARY } from '../../../../../attrs/colors';
import { periodObject } from '../../../../../helpers/periodObject';
import { GraphTemplateCard } from '../GraphTemplateCard';
import EmptyState from '../../../../../components/EmptyState';
import { StepIconComponent } from '../StepIconComponent';

const steps = ['Step1', 'Step2', 'Step3'];

const periods = [
  { key: 'last_8_hours', name: 'Last 8 hours', value: 'last_8_hours' },
  { key: 'last_4_hours', name: 'Last 4 hours', value: 'last_4_hours' }
];

export function CloneNewTemplate({ machineId, open, handleCloseDialog }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const graphTemplates = useSelector(getAllGraphTemplates);

  const treeView = useSelector(compose(getData, getMachineSensorsTreeView));
  const [filteredTreeData, setFilteredTreeData] = useState(() =>
    treeView && treeView.production_lines ? treeView.production_lines : []
  );

  const sensors = useSelector(compose(getData, getSensors));
  const [sensorList, setSensorList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [templateName, setTemplateName] = useState('');
  const [sensor, setSensor] = useState('');
  const [isChecked, setIsChecked] = useState(true);
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [templateIdentification, setTemplateIdentification] = useState('');
  const [filterTime, setFilterTime] = useState(periods[0].value);
  const [description, setDescription] = useState('');
  const [accessType, setAccessType] = useState('ACCESS_TYPE_USER_ONLY');
  const [selectedCardId, setSelectedCardId] = useState('');
  const [alertSelectSensors, setAlertSelectSensors] = useState(false);
  const [alertSelectTemplate, setAlertSelectTemplate] = useState(false);
  const [clean, setClean] = useState(false);
  const [errors, setErrors] = useState({
    templateIdentification: '',
    description: ''
  });

  const resetAllStates = () => {
    setFilteredTreeData(() => (treeView && treeView.production_lines ? treeView.production_lines : []));
    setSensorList([]);
    setActiveStep(0);
    setTemplateName('');
    setSensor('');
    setIsChecked(true);
    setSelectedSensors([]);
    setSelectedItems([]);
    setTemplateIdentification('');
    setFilterTime(periods[0].value);
    setDescription('');
    setAccessType('ACCESS_TYPE_USER_ONLY');
    setSelectedCardId('');
    setAlertSelectSensors(false);
    setAlertSelectTemplate(false);
    setClean(false);
    setErrors({});
  };

  useEffect(() => {
    if (!open) {
      resetAllStates();
    }
  }, [open]);

  const loadTemplates = useCallback(() => {
    dispatch(
      loadGraphsTemplates({
        machineId,
        accessType: isChecked ? 'ACCESS_TYPE_ALL_ORG' : 'ACCESS_TYPE_USER_ONLY',
        sensor,
        templateName
      })
    );
  }, [dispatch, machineId, isChecked, sensor, templateName]);

  useEffect(() => {
    if (sensors && sensors?.length > 0) {
      const newSensors =
        sensors?.map(item => {
          const millSensor = getMillSensor(item?.type);
          const sensorName = t(millSensor?.name || item?.custom_sensor_name);
          return { title: sensorName, value: item?.type, type: item?.type };
        }) || [];
      setSensorList(newSensors.filter(ns => !isNullUndefinedOrEmpty(ns.title)));
    }
  }, [sensors]);

  useEffect(() => {
    if (machineId) {
      loadTemplates();
    }
  }, [machineId, isChecked, templateName, sensor, loadTemplates]);

  useEffect(() => {
    const params = {
      productionLines: [],
      sensors: [],
      machineIds: []
    };
    dispatch(loadMachinesSensorsTreeView(params));
  }, [dispatch]);

  useEffect(() => {
    const params = {
      machineId,
      templateName: ''
    };
    dispatch(loadMachinesensorsbyid(params));
  }, [dispatch, machineId]);

  const handleSensorChange = (_event, newValue) => {
    setSelectedSensors(newValue);
    setSensor(newValue?.map(item => item.value) || []);
  };

  const handleChangeOnlyMe = event => {
    setIsChecked(event.target.checked);
  };

  const handleSelectCard = id => {
    setSelectedCardId(id);
  };

  const handleSelectionChange = items => {
    const extractedData = items.flatMap(item =>
      item.childrenNames.map((child, idx) => ({
        machine_id: item.machineID,
        type: child.type,
        auto_scale: true,
        line_color: generateColor(idx),
        line_style: 'LINE_STYLE_SOLID',
        line_thickness: 1,
        max_scale: child.max_scale || null,
        min_scale: child.min_scale || null,
        is_custom: child.is_custom || false
      }))
    );

    setSelectedItems(extractedData);
  };

  useEffect(() => {
    if (selectedCardId) {
      const selectedTemplate = graphTemplates.find(template => template.id === selectedCardId);
      if (selectedTemplate) {
        setTemplateIdentification(`${t('dialog.clone_template.copy')} - ${selectedTemplate.name}`);
        setFilterTime(selectedTemplate.period_type);
        setDescription(selectedTemplate.description);
        setAccessType(selectedTemplate.tmpl_access_type);

        if (selectedTemplate.sensors.length > 0) {
          const initialSelectedItems = selectedTemplate.sensors.map((sensorCloned, idx) => ({
            machine_id: sensorCloned.machine_id,
            type: sensorCloned.type,
            auto_scale: sensorCloned.auto_scale,
            line_color: sensorCloned.line_color || generateColor(idx),
            line_style: sensorCloned.line_style || 'LINE_STYLE_SOLID',
            line_thickness: sensorCloned.line_thickness || 1,
            max_scale: sensorCloned.max_scale || null,
            min_scale: sensorCloned.min_scale || null,
            is_custom: sensorCloned.is_custom || false
          }));

          setSelectedItems(initialSelectedItems);
        }
      }
    }
  }, [selectedCardId, graphTemplates]);

  const validateFields = () => {
    const validationErrors = {};
    if (!templateIdentification) {
      validationErrors.templateIdentification = t('dialog.clone_template.validation.template_identification_required');
    }
    if (!description) {
      validationErrors.description = t('dialog.clone_template.validation.description_required');
    }
    return validationErrors;
  };

  const handleInputChange = (fieldName, value) => {
    switch (fieldName) {
      case 'templateIdentification':
        setTemplateIdentification(value);
        break;
      case 'description':
        setDescription(value);
        break;
      default:
        break;
    }
    setErrors(prevErrors => ({
      ...prevErrors,
      [fieldName]: ''
    }));
  };

  const handleInputBlur = fieldName => {
    const error = validateFields(fieldName);
    setErrors(prevErrors => ({
      ...prevErrors,
      [fieldName]: error || prevErrors[fieldName]
    }));
  };

  useEffect(() => {
    const results = treeView.production_lines;
    setFilteredTreeData(results);
  }, [treeView]);

  const handleNext = () => {
    if (activeStep === 0 && !selectedCardId) {
      setAlertSelectTemplate(true);
    } else if (activeStep === 1 && selectedItems.length < 2) {
      setAlertSelectSensors(true);
    } else {
      setAlertSelectSensors(false);
      setAlertSelectTemplate(false);
      setActiveStep(prev => prev + 1);
    }
  };
  const handleBack = () => setActiveStep(prevActiveStep => prevActiveStep - 1);
  const handleClose = () => handleCloseDialog();

  const handleSave = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const values = {
      favorite: false,
      machine_id: machineId,
      name: templateIdentification,
      description,
      period_type: filterTime,
      sensors: selectedItems,
      tmpl_access_type: accessType
    };
    dispatch(createGraphsTemplate(values));
    handleCloseDialog();
  };

  const content = (
    <Box sx={{ minWidth: { sm: '320px', md: '600px', lg: '800px' } }}>
      {alertSelectSensors && (
        <Grid item xs={12}>
          <Alert severity="error">{t('dialog.clone_template.alert_to_select_sensors')}</Alert>
        </Grid>
      )}
      {alertSelectTemplate && (
        <Grid item xs={12}>
          <Alert severity="error">{t('dialog.clone_template.alert_to_select_template')}</Alert>
        </Grid>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '300px',
          m: '42px auto'
        }}
      >
        <Stepper activeStep={activeStep} sx={{ width: '200px' }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <CustomStepLabel
                active={index === activeStep}
                completed={index < activeStep}
                StepIconComponent={props => StepIconComponent({ ...props, icon: index + 1 })}
              />
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === 0 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormElements.TextField
                label={t('dialog.clone_template.search_by_graphs_template_name')}
                fullWidth
                bgcolor="#F7F7FA"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                      <NotifyIcon iconName="search" fontSize="default" color="primary" />
                    </InputAdornment>
                  )
                }}
                onChange={e => setTemplateName(e.target.value)}
                value={templateName}
                data-testid="clone-template-modal-search-by-graph-template-name-field"
                sx={{
                  mt: { md: 1 },
                  mb: { md: 1 },
                  input: { bgcolor: '#F7F7FA !important' },
                  '.MuiTextField-root > .MuiInputBase-input': { bgcolor: '#F7F7FA !important' }
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormElements.MultiSelect
                label={t('dialog.clone_template.filter_by_sensor')}
                bgcolor="#F7F7FA"
                name="filter_sensor"
                id="filter_sensor"
                activeColor={COLOR_PRIMARY}
                fullWidth
                value={selectedSensors}
                setFieldValue={handleSensorChange}
                options={sensorList}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ position: 'absolute', right: '12px', bottom: '22px' }}>
                      <NotifyIcon iconName="filterOutlined" fontSize="default" color="primary" />
                    </InputAdornment>
                  )
                }}
                data-testid="dialog-add-clone-template-filter-by-sensor-field"
                clear={clean}
                setClear={setClean}
                sx={{
                  mt: { md: 1 },
                  mb: { md: 1 }
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormElements.Checkbox
                activeColor={COLOR_PRIMARY}
                label={t('machines.detail.tabs.templates.see_all_graph_templates')}
                checked={isChecked}
                onChange={handleChangeOnlyMe}
                data-testid="clone-template-modal-see-all-graphs-templates-field"
                sx={{
                  mt: { md: 1 },
                  mb: { md: 1 }
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {graphTemplates && graphTemplates.length > 0 ? (
                graphTemplates.map(graph => (
                  <GraphTemplateCard
                    key={graph?.id}
                    graph={graph}
                    isRadio
                    checked={selectedCardId === graph.id}
                    onSelectCard={() => handleSelectCard(graph.id)}
                  />
                ))
              ) : (
                <Grid container>
                  <Grid item xs={12}>
                    <EmptyState type="graph_templates" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      )}
      {activeStep === 1 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {graphTemplates && graphTemplates.length > 0 ? (
                graphTemplates
                  .filter(graph => graph.id === selectedCardId)
                  .map(graph => <GraphTemplateCard key={graph.id} notClick graph={graph} />)
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {t('machines.detail.tabs.templates.no_templates_found')}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionTitle>
                {t('dialog.clone_template.select_the_information_you_want_to_include_in_the_new_template')}
              </SectionTitle>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomTreeView
                rawData={filteredTreeData}
                selectedItems={selectedItems}
                onSelectionChange={handleSelectionChange}
              />
            </Grid>
          </Grid>
        </>
      )}
      {activeStep === 2 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormElements.TextField
                label={t('dialog.add_new_template.template_identification')}
                size="medium"
                name="template_identification"
                id="template_identification"
                fullWidth
                bgcolor="#F7F7FA"
                placeholder="CLONE"
                onChange={e => {
                  handleInputChange('templateIdentification', e.target.value);
                  setErrors({});
                }}
                onBlur={handleInputBlur}
                value={templateIdentification}
                error={!!errors.templateIdentification}
                helperText={errors.templateIdentification}
                data-testid="template_identification"
              />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginTop: '-16px' }}>
                <FormElements.Select
                  name="filter_time"
                  id="filter_time"
                  fullWidth
                  value={filterTime}
                  onChange={e => setFilterTime(e.target.value)}
                  label={t('dialog.add_new_template.filter_time')}
                >
                  {periodObject.map(ftime => (
                    <option key={ftime.label} value={ftime.value}>
                      {`${t(ftime.label)}`}
                    </option>
                  ))}
                </FormElements.Select>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormElements.TextField
                className="subject"
                label={t('dialog.add_new_template.description')}
                id="description"
                margin="normal"
                name="description"
                multiline
                rows={6}
                required
                fullWidth
                onChange={e => {
                  handleInputChange('description', e.target.value);
                  setErrors({});
                }}
                onBlur={handleInputBlur}
                value={description}
                error={!!errors.description}
                helperText={errors.description}
                data-testid="dialog-add-new-template-desciption-field"
                sx={{
                  mt: { md: 1 },
                  mb: { md: 1 }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel>{t('dialog.clone_template.your_template')}</FormLabel>
                <RadioGroup
                  id="accessType"
                  name="accessType"
                  value={accessType}
                  onChange={e => setAccessType(e.target.value)}
                  sx={{ marginBottom: '20px' }}
                >
                  <FormControlLabel
                    value="ACCESS_TYPE_USER_ONLY"
                    control={<Radio />}
                    label={t('dialog.clone_template.access_type.only_me')}
                  />
                  <FormControlLabel
                    value="ACCESS_TYPE_ALL_ORG"
                    control={<Radio />}
                    label={t('dialog.clone_template.access_type.all_users')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );

  const actionBar = (
    <>
      <Button
        variant="text"
        color="secondary"
        onClick={handleClose}
        sx={{ width: '127px', height: '56px', padding: '16px' }}
      >
        {t('default_actions.cancel')}
      </Button>

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ width: '127px', height: '56px', padding: '16px', mr: '24px' }}
        >
          {t('default_actions.back')}
        </Button>

        <Box sx={{ flex: '1 1 auto' }} />
        {activeStep === steps.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ width: '127px', height: '56px', padding: '16px' }}
            type="submit"
          >
            {t('default_actions.save')}
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            sx={{ width: '127px', height: '56px', padding: '16px' }}
            onClick={handleNext}
            type="submit"
          >
            {t('default_actions.next')}
          </Button>
        )}
      </Box>
    </>
  );

  const handleOk = () => {
    handleCloseDialog();
  };

  return (
    <NewDialog
      open={open}
      handleClose={handleCloseDialog}
      handleCancel={handleCloseDialog}
      handleOk={handleOk}
      title={t('dialog.clone_template.title')}
      subTitle={t('dialog.clone_template.subtitle')}
      content={content}
      displayActionBar="flex"
      justifyContentActionBar="space-between"
      paddingXContent={{ sm: '1rem', md: '2rem', lg: '3rem' }}
      actionBar={actionBar}
    />
  );
}

CloneNewTemplate.propTypes = {
  open: T.bool.isRequired,
  handleCloseDialog: T.func.isRequired,
  machineId: T.string.isRequired
};
