import React from 'react';
import { useTranslation } from 'react-i18next';
import T from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { FormHelperText } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FormElements, NotifyTypo } from 'web-components';

import { COLOR_PRIMARY } from '../../../../attrs/colors';
import { showMachineState } from '../../../../redux/ui/settings/actions';
import { getSensorSettingByIds } from '../../../../redux/ui/settings/selectors';
import MachineLegendTooltip from '../MachineLegendTooltip';
import { MachineStateSelectionWrapper, CheckboxWrapper } from './elements';
import { getData, getLoadingState } from '../../../../redux/rootSelectors';
import { getSelectedMetrics, getSelectedScopedMachineHistory } from '../../../../redux/machines/selectors';
import { isSuccess } from '../../../../helpers/utils';

const MachineStateSelection = ({ ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, sensorType } = useParams();
  const sensorSettings = useSelector(state => getSensorSettingByIds(state, id, sensorType));
  const machineHistory = useSelector(compose(getData, getSelectedScopedMachineHistory));
  const { status } = useSelector(compose(getLoadingState, getSelectedMetrics));
  const hasMachineHistory = Boolean(machineHistory.length);
  const isMachineHistoryLoaded = isSuccess(status);
  const isMachineHistoryEmpty = isMachineHistoryLoaded && !hasMachineHistory;

  const currentMachineStateSetting = (sensorSettings || {}).showMachineState || false;

  const handleClick = () => {
    dispatch(showMachineState(id, sensorType, !currentMachineStateSetting));
  };

  return (
    <MachineStateSelectionWrapper {...props}>
      <div style={{ display: 'flex', marginRight: `${!props.mobile && '2rem'}` }}>
        {!props.isMobile && <MachineLegendTooltip />}
        {!props.mobile ? <NotifyTypo.Body1>{t('dashboard.sensors.legend')}</NotifyTypo.Body1> : null}
      </div>
      {props.showMachineStatus && (
        <CheckboxWrapper showHelperText={isMachineHistoryEmpty}>
          <FormElements.Checkbox
            data-testid="machine-status-change-checkbox-button"
            checked={!isMachineHistoryEmpty && currentMachineStateSetting}
            disabled={isMachineHistoryEmpty}
            activeColor={COLOR_PRIMARY}
            label={t('dashboard.sensors.show_machine_state_changes')}
            onChange={handleClick}
          />
          {isMachineHistoryEmpty && <FormHelperText error>{t('dashboard.sensors.no_machine_data')}</FormHelperText>}
        </CheckboxWrapper>
      )}
    </MachineStateSelectionWrapper>
  );
};

MachineStateSelection.propTypes = {
  isMobile: T.bool,
  mobile: T.bool,
  showMachineStatus: T.bool
};

MachineStateSelection.defaultProps = {
  isMobile: false,
  mobile: false,
  showMachineStatus: true
};

export default MachineStateSelection;
