import React from 'react';
import T from 'prop-types';
import { Content, PaperStyled } from './MobileView.styled';
import MobileRow from './MobileRow';
import { MachinesColumnProps, MachinesRowProps } from '../MachinesOverview.config';
import Loading from './Loading';

const MobileView = ({ columns, rows, footer, onClick, onEdit, isLoading, dataSelectorTable, dataSelectorRows }) => (
  <>
    <PaperStyled square data-testid={dataSelectorTable}>
      <Content>
        {rows.map(row => (
          <MobileRow
            key={`mobile-row-${row.id}`}
            columns={columns}
            row={row}
            onClick={onClick}
            onEdit={onEdit}
            data-testid={dataSelectorRows}
          >
            {row.id}
          </MobileRow>
        ))}
      </Content>
      {footer}
      <Loading visible={isLoading} />
    </PaperStyled>
  </>
);

MobileView.propTypes = {
  rows: T.arrayOf(MachinesRowProps),
  footer: T.node,
  columns: MachinesColumnProps.isRequired,
  onClick: T.func,
  onEdit: T.func,
  isLoading: T.bool,
  dataSelectorTable: T.string,
  dataSelectorRows: T.string
};

MobileView.defaultProps = {
  rows: [],
  footer: null,
  onClick: () => null,
  onEdit: () => null,
  isLoading: false,
  dataSelectorTable: 'machine-table',
  dataSelectorRows: 'machine-table-row'
};

export default MobileView;
