import React from 'react';
import T from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NotifyTypo } from 'web-components';
import { compose } from 'redux';
import { Stack } from '@mui/material';
import {
  getSelectedSensor,
  getSelectedSensorLastMetric,
  getMachineNotificationRules
} from '../../../../redux/machines/selectors';
import { getSensorValue, getUnit } from '../../../../helpers/utils';
import { GraphHeading, SensorCurrentValue, ContainerGraphHeading } from './elements';
import { getData } from '../../../../redux/rootSelectors';

const { SensorCardValue } = NotifyTypo;

const CurrentValue = ({ isMobile }) => {
  const { t } = useTranslation();
  const { sensor } = useSelector(compose(getData, getSelectedSensor));
  const lastMetric = useSelector(compose(getData, getSelectedSensorLastMetric));
  const { data: notificationRules } = useSelector(getMachineNotificationRules);

  const hasNotificationRule =
    notificationRules.length > 0 ? t('dashboard.sensors.enabled') : t('dashboard.sensors.disabled');

  return (
    <Stack
      direction="row"
      spacing={12}
      sx={{
        justifyContent: isMobile ? 'space-between' : 'flex-start',
        alignItems: 'center'
      }}
    >
      <ContainerGraphHeading>
        <GraphHeading>{t('dashboard.sensors.current_value')}</GraphHeading>
        <SensorCurrentValue className={isMobile && 'mobile'}>
          {lastMetric ? (
            <SensorCardValue
              data-testid="historical-sensor-current-value"
              style={{ fontSize: '1rem', fontWeight: '400' }}
            >
              {getSensorValue(sensor, lastMetric.value)}{' '}
              {sensor.is_custom ? sensor.custom_unit : getUnit(sensor.unit, sensor.name)}
            </SensorCardValue>
          ) : (
            '---'
          )}
        </SensorCurrentValue>
      </ContainerGraphHeading>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <GraphHeading>{t('dashboard.sensors.notification_set_up')}</GraphHeading>
        <SensorCurrentValue className={isMobile && 'mobile'}>
          <SensorCardValue data-testid="notification-set-up" style={{ fontSize: '1rem', fontWeight: '400' }}>
            {hasNotificationRule}
          </SensorCardValue>
        </SensorCurrentValue>
      </div>
    </Stack>
  );
};

CurrentValue.propTypes = {
  isMobile: T.bool
};

CurrentValue.defaultProps = {
  isMobile: false
};

export default CurrentValue;
