import { ERROR, WARNING, SUCCESS, INFO } from './status';

// CUSTOMIZABLE COLORS
export const COLOR_MAIN_HIGHLIGHT = ((window || {}).colors || {}).MAIN_HIGHLIGHT || '#2B2F42';
export const COLOR_MAIN_HIGHLIGHT_TEXT = ((window || {}).colors || {}).MAIN_HIGHLIGHT_TEXT || '#FFFFFF';
export const COLOR_MAIN_HIGHLIGHT_TEXT_SECONDARY =
  ((window || {}).colors || {}).MAIN_HIGHLIGHT_TEXT_SECONDARY || '#B1B7C5';
export const COLOR_PRIMARY = ((window || {}).colors || {}).PRIMARY || '#2BACA4';
export const COLOR_PRIMARY_CONTRAST = ((window || {}).colors || {}).PRIMARY_CONTRAST || '#FFFFFF';
export const COLOR_SECONDARY = ((window || {}).colors || {}).SECONDARY || '#F13309';
export const COLOR_SECONDARY_CONTRAST = ((window || {}).colors || {}).SECONDARY_CONTRAST || '#FFFFFF';
export const COLOR_WARNING = ((window || {}).colors || {}).WARNING || '#F19909';
export const COLOR_WARNING_CONTRAST = ((window || {}).colors || {}).WARNING_CONTRAST || '#FFFFFF';
export const COLOR_ERROR = ((window || {}).colors || {}).ERROR || '#F13309';
export const COLOR_ERROR_CONTRAST = ((window || {}).colors || {}).ERROR_CONTRAST || '#FFFFFF';
export const COLOR_SUCCESS = ((window || {}).colors || {}).SUCCESS || '#59D66E';
export const COLOR_SUCCESS_CONTRAST = ((window || {}).colors || {}).SUCCESS_CONTRAST || '#00000026';

// GLOBALS
export const WHITE = '#FFFFFF';
export const LIGHT_GREY = '#F7F7FA';
export const LIGHT_GREY_OPAQUE = '#F7F7FA80';
export const LIGHTER_GREY = '#EAEAEC';
export const DARKER_GREY = '#9597A0';
export const PRIMARY_TEXT_COLOR = '#23252F';
export const PRIMARY_LIGHT_BACKGROUND = '#EEF8F7';
export const SECONDARY_TEXT_COLOR = '#7A7D85';
export const DISABLED_COLOR = '#00000026';

// INPUTS
export const INPUT_BACKGROUND = LIGHT_GREY;
export const INPUT_BACKGROUND_DISABLED = '#D6D6D6';

// SENSOR COMPARISON
export const TEXTAREA_BACKGROUND = LIGHT_GREY;
export const TEXTAREA_BACKGROUND_DISABLED = INPUT_BACKGROUND_DISABLED;
export const COMPARISON_CARD_BORDER = LIGHTER_GREY;
export const COMPARISON_PLACEHOLDER_CHARD_BORDER = '#D5D5D9';
export const COMPARISON_CARD_SELECTED_LIGHT = '#DEF1EE';
export const MENU_ITEM_BORDER = '#EBEBEB';

// GRAPHS
export const AXIS_LINE_COLOR = DARKER_GREY;
export const GRAPH_COMPARE_LINE_1 = '#00A4FF';
export const GRAPH_COMPARE_LINE_2 = '#10AD87';
export const GRAPH_COMPARE_LINE_3 = '#BA589F';
export const GRAPH_COMPARE_LINE_4 = '#C12907';
export const GRAPH_COMPARE_LINE_5 = '#880E4F';
export const GRAPH_COMPARE_LINE_6 = '#F19909';
export const GRAPH_BRUSH_OVERLAY_COLOR = 'rgba(0,0,0,.9)';

// MACHINE STATI
export const MACHINE_MAINTENANCE_COLOR = '#397CCA';
export const MACHINE_RUNNING_COLOR = '#59D66E';
export const MACHINE_CRITICAL_COLOR = '#F13309';
export const MACHINE_WARNING_COLOR = '#F19909';
export const MACHINE_IDLE_COLOR = '#5C35AE';
export const MACHINE_NO_DATA_COLOR = '#7A7D85';
export const MACHINE_FUTURE_COLOR = 'rgba(255,255,255,0)'; // invisible

export const SENSOR_OK_COLOR = '#59D66E';
export const SENSOR_NODATA_COLOR = '#2B2F42';
export const SENSOR_WARNING_COLOR = '#F19909';
export const SENSOR_CRITICAL_COLOR = '#F13309';

// Machine status tracked/untracked
export const MACHINE_STATUS_TRACKED_COLOR = '#2FB259';
export const MACHINE_STATUS_TRACKED_SECONDARY_COLOR = '#EAF7EE';
export const MACHINE_STATUS_UNTRACKED_COLOR = '#F13309';
export const MACHINE_STATUS_UNTRACKED_SECONDARY_COLOR = '#FEEBE6';

export const EDIT_ICON_DEFAULT_COLOR = '#2BACA3';

// NOTIFICATION COLORS
export const NOTIFICATION_COLORS = {
  [ERROR]: COLOR_ERROR,
  [WARNING]: COLOR_WARNING,
  [SUCCESS]: COLOR_SUCCESS,
  [INFO]: COLOR_PRIMARY
};

export const SNACKBAR_BACKGROUND = '#333333';
