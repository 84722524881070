import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { useResolutionCheck } from 'web-components';

import { ChartWrapper } from '../elements';
import MultipleSensorsBrush from './MultipleSensorsBrush';
import MultipleSensorsGraph from './MultipleSensorsGraph';
import { getSelectedMachineSensorComparisonSensorData } from '../../../redux/machines/selectors';

const MultipleSensorsChart = ({ machineId }) => {
  const { isMobile } = useResolutionCheck();
  const sensors = useSelector(compose(getSelectedMachineSensorComparisonSensorData));

  return (
    <ChartWrapper
      data-testid="compare-sensor-status-chart"
      style={{ paddingRight: sensors.length <= 1 ? '1rem' : 0 }}
      mobile={isMobile}
    >
      <MultipleSensorsGraph machineId={machineId} />
      <MultipleSensorsBrush machineId={machineId} />
    </ChartWrapper>
  );
};

MultipleSensorsChart.propTypes = {
  machineId: T.string.isRequired
};

export default MultipleSensorsChart;
