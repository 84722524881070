import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Loader } from 'web-components';

import SalesforceModal from 'components/SalesforceModal';

import T from 'prop-types';
import DateTimeProvider from 'components/DateTime/DateTimeProvider';
import { logout } from './redux/auth/actions';
import { subscribeToAlertsStream } from './redux/alerts/actions';
import { isSuccess } from './helpers/utils';
import Navbar from './components/Navbar';
import getRoutesByRole from './helpers/routeGeneration';
import { MainContainer } from './elements';
import { getUserFeatures, getUserRole, getUserData } from './redux/user/selectors';
import SupportModal from './components/SupportModal';

const LoggedIn = ({ prevPath }) => {
  const [loading, setLoading] = useState(true);
  const [routes, setRoutes] = useState(null);
  const dispatch = useDispatch();
  const role = useSelector(getUserRole);
  const features = useSelector(getUserFeatures);
  const user = useSelector(getUserData);
  const { loadingState: wsLoadingState } = useSelector(state => state.stream);
  const userName = `${user.first_name} ${user.last_name}`;

  useEffect(() => {
    if (role) {
      // dispatch(loadUser());
      setRoutes(getRoutesByRole(role, features));
      setLoading(false);
    } else {
      dispatch(logout());
    }
  }, [dispatch, role, features]);

  useEffect(() => {
    if (isSuccess(wsLoadingState.status)) {
      dispatch(subscribeToAlertsStream());
    }
  }, [dispatch, wsLoadingState]);

  const isReadyToRender = !loading && routes;

  return isReadyToRender ? (
    <DateTimeProvider>
      <>
        <Navbar routes={routes} hasBack={!prevPath} userName={userName} />
        <MainContainer maxWidth={false} data-testid="main-container">
          <Switch>
            {routes.map(route => (
              <Route key={route.name} exact component={route.component} path={route.path} />
            ))}
            {prevPath ? <Redirect to={prevPath} /> : <Redirect to="/dashboard" />}
          </Switch>
          <SupportModal />
          <SalesforceModal />
        </MainContainer>
      </>
    </DateTimeProvider>
  ) : (
    <Loader standalone />
  );
};

LoggedIn.defaultProps = {
  prevPath: null
};

LoggedIn.propTypes = {
  prevPath: T.shape({})
};

export default LoggedIn;
