import React from 'react';
import T from 'prop-types';

import EmptyState from '../../../../components/EmptyState';

import LastWeekMetrics from './LastWeekmetrics';
import ThisMonthUpToday from './ThisMonthUpToday';
import LastMonth from './LastMonth';

const Metrics = ({ metricsMachines }) => {
  const renderMetrics = (data, Component) => {
    if (data && Object.keys(data).length > 0) {
      return <Component metrics={data} />;
    }
    return <EmptyState type="metrics" />;
  };

  return (
    <>
      {renderMetrics(metricsMachines.last_week, LastWeekMetrics)}
      {renderMetrics(metricsMachines.up_to_today, ThisMonthUpToday)}
      {renderMetrics(metricsMachines.last_month, LastMonth)}
    </>
  );
};

Metrics.propTypes = {
  machine: T.shape({
    id: T.oneOfType([T.string, T.number]).isRequired
  }).isRequired,
  metricsMachines: T.exact({
    last_month: T.exact({}),
    last_week: T.exact({}),
    up_to_today: T.exact({})
  }).isRequired
};

export default Metrics;
