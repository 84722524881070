import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputAdornment, Button, Grid } from '@mui/material';
import { NotifyTypo, DialCodes, FormElements } from 'web-components';
import { isError } from '../../../helpers/utils';
import RouteLeavingCheck from '../../elements/RouteLeavingCheck';
import { LANGUAGES } from '../../../attrs/languages';
import FormAlert from '../FormAlert';
import { ContainerForm } from './elements';
import { COLOR_PRIMARY } from '../../../attrs/colors';
import { iUser } from '../../../types/interfaces/iUser';

interface AccountFormFieldsProps {
  user: iUser;
  values: iUser;
  dirty: boolean;
  handleChange: () => void;
  handleBlur: () => void;
  errors: {
    first_name: string;
    last_name: string;
    phone: {
      number: string;
    };
  };
  touched: {
    first_name: boolean;
    last_name: boolean;
    phone: {
      number: boolean;
    };
  };
  selectedDialCode: string;
  updateLoadingState: {
    status: string;
    message?: string;
  };
}

const AccountFormFields = ({
  user,
  dirty,
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  selectedDialCode,
  updateLoadingState
}: AccountFormFieldsProps) => {
  const { t } = useTranslation();

  return (
    <ContainerForm maxWidth="sm">
      <NotifyTypo.InfoText align="center" gutterBottom style={{ marginBottom: '1rem' }}>
        ({t('profile.account_register_info_text')}
        <b>{user['e-mail']}</b>)
      </NotifyTypo.InfoText>
      <NotifyTypo.Heading2>{t('profile.personal_information')}</NotifyTypo.Heading2>
      <RouteLeavingCheck when={dirty} />
      <form>
        <FormElements.TextField
          label={t('profile.first_name')}
          fullWidth
          id="firstName"
          margin="normal"
          name="first_name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.first_name}
          required
          error={errors.first_name && touched.first_name}
          helperText={errors.first_name && touched.first_name && t(`form.validate.${errors.first_name}`)}
          size={undefined}
          type={undefined}
          sx={undefined}
          bgcolor={undefined}
        />
        <FormElements.TextField
          label={t('profile.last_name')}
          fullWidth
          id="lastName"
          margin="normal"
          name="last_name"
          value={values.last_name}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={errors.last_name && touched.last_name}
          helperText={errors.last_name && touched.last_name && t(`form.validate.${errors.last_name}`)}
          size={undefined}
          type={undefined}
          sx={undefined}
          bgcolor={undefined}
        />
        <FormElements.Select
          value={values.phone.country}
          fullWidth
          handleChange={handleChange}
          label={t('profile.country_code')}
          name="phone.country"
          id="phoneCountry"
          className={undefined}
          size={undefined}
          withEmptyField={undefined}
          margin={undefined}
          required={undefined}
          error={undefined}
          helperText={undefined}
          native={undefined}
          disabled={undefined}
          iconName={undefined}
        >
          {DialCodes.map(country => (
            <option key={country.code} value={country.code}>
              {`${t(country.name)} (${country.dial_code})`}
            </option>
          ))}
        </FormElements.Select>
        <FormElements.TextField
          label={t('profile.phone')}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">{selectedDialCode}</InputAdornment>
          }}
          id="phoneNumber"
          margin="normal"
          name="phone.number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone.number}
          required
          error={((errors || {}).phone || {}).number && ((touched || {}).phone || {}).number}
          helperText={
            ((errors || {}).phone || {}).number &&
            ((touched || {}).phone || {}).number &&
            t(`form.validate.${errors.phone.number}`)
          }
          size={undefined}
          type={undefined}
          sx={undefined}
          bgcolor={undefined}
        />
        <NotifyTypo.Heading2 gutterBottom style={{ marginTop: '2rem' }}>
          {t('profile.account_settings')}
        </NotifyTypo.Heading2>
        <FormElements.Select
          value={values.language}
          handleChange={handleChange}
          fullWidth
          label={t('profile.languages.language')}
          name="language"
          id="language"
          className={undefined}
          size={undefined}
          withEmptyField={undefined}
          margin={undefined}
          required={undefined}
          error={undefined}
          helperText={undefined}
          native={undefined}
          disabled={undefined}
          iconName={undefined}
        >
          {LANGUAGES.map(language => (
            <option key={language.value} value={language.value}>
              {t(language.label)}
            </option>
          ))}
        </FormElements.Select>
        <Grid container sx={{ mt: '24px', mb: '24px' }}>
          <FormElements.Checkbox
            checked={values.allow_weekly_machine_summary_notifications}
            activeColor={COLOR_PRIMARY}
            name="allow_weekly_machine_summary_notifications"
            id="allow_weekly_machine_summary_notifications"
            label={t('profile.allow_weekly_metrics_emails')}
            className=""
            disabled={false}
            onChange={handleChange}
          />
        </Grid>
        <Button
          component={Link}
          variant="outlined"
          size="large"
          style={{
            marginTop: '1.5rem',
            paddingTop: '1rem',
            paddingBottom: '1rem'
          }}
          to="/account/reset-password"
        >
          {t('profile.change_password')}
        </Button>
        {isError(updateLoadingState.status) && <FormAlert message={t('errors.form_submit')} />}
      </form>
    </ContainerForm>
  );
};

export default AccountFormFields;
