import React, { forwardRef } from 'react';
import T from 'prop-types';
import { MachineIcon, NotifyIcon, useResolutionCheck } from 'web-components';
import { IconButton, useMediaQuery } from '@mui/material';

import NotifyTypo from 'web-components/build/NotifyTypo';
import { getSensorProps } from '../../helpers/utils';
import { StyledSelectedComparisonCard, ContentSensorCard, WrapperContentSensorCard } from './elements';

const SelectedComparisonSensorCard = forwardRef(({ sensor, onCancelClick, ...props }, ref) => {
  const { isDesktop } = useResolutionCheck();
  const isTablet = useMediaQuery('(max-width:768px)');
  const isMobile = useMediaQuery('(max-width:500px)');

  const machineIconProps = {
    /* eslint-disable camelcase */
    iconName: getSensorProps(sensor.is_custom ? sensor.custom_icon_type : sensor.mapValue).icon,
    status: sensor.status,
    fontSize: isDesktop ? 'large' : 'default',
    style: {
      marginRight: isDesktop ? '1rem' : 0
    },
    viewBox: '0 0 32 32'
  };

  const additionalCardProps = !isDesktop ? { onClick: e => onCancelClick(e, sensor.type) } : {};

  return (
    <StyledSelectedComparisonCard
      ref={ref}
      elevation={3}
      data-testid="selected-sensor-card"
      mobile={isDesktop ? 'false' : 'true'}
      {...additionalCardProps}
      {...props}
    >
      {isTablet && !isMobile ? (
        <WrapperContentSensorCard>
          <ContentSensorCard>
            <MachineIcon {...machineIconProps} />
            <NotifyTypo.Caption
              display="block"
              align="center"
              style={{
                lineHeight: '1.2rem',
                maxHeight: '100%',
                width: '100%',
                overflow: 'hidden',
                wordBreak: 'break-word'
              }}
            >
              {sensor.name}
            </NotifyTypo.Caption>
            <IconButton data-testid="selected-sensor-card-cancel-button" onClick={e => onCancelClick(e, sensor.type)}>
              <NotifyIcon fontSize="default" iconName="cancel" color="primary" />
            </IconButton>
          </ContentSensorCard>
        </WrapperContentSensorCard>
      ) : (
        <ContentSensorCard>
          <MachineIcon {...machineIconProps} />
          <NotifyTypo.Caption
            display="block"
            style={{
              lineHeight: '1.2rem',
              maxHeight: '100%',
              width: '100%',
              overflow: 'hidden',
              wordBreak: 'break-word'
            }}
          >
            {sensor.name}
          </NotifyTypo.Caption>
          <IconButton data-testid="selected-sensor-card-cancel-button" onClick={e => onCancelClick(e, sensor.type)}>
            <NotifyIcon fontSize="default" iconName="cancel" color="primary" />
          </IconButton>
        </ContentSensorCard>
      )}
    </StyledSelectedComparisonCard>
  );
});

SelectedComparisonSensorCard.propTypes = {
  sensor: T.shape({
    mapValue: T.string,
    name: T.string,
    type: T.string,
    status: T.string,
    is_custom: T.bool,
    custom_icon_type: T.string
  }),
  onCancelClick: T.func
};

SelectedComparisonSensorCard.defaultProps = {
  onCancelClick: null,
  sensor: null
};

export default SelectedComparisonSensorCard;
