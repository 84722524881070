import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCurrentTemplate } from 'redux/graphTemplates/selectors';
import { getGraphsTemplatesReducerState } from 'redux/rootSelectors';
import { isSuccess } from 'helpers/utils';
import EditInfoChartTemplateDetails from './EditInfoChartTemplateDetails';
import EditPropsChartTemplateDetails from './EditPropsChartTemplateDetails';
import { getGraphsTemplateById, updateGraphsTemplate } from '../../../../../../redux/graphTemplates/actions';

export function ContainerEditChartTemplateDetails() {
  const { id, templateId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentTemplateEqual = (oldObj, newObj) => oldObj?.id === newObj?.id;
  const template = useSelector(getCurrentTemplate, currentTemplateEqual);
  const [editingTemplate, setEditingTemplate] = useState();
  const [isSaving, setIsSaving] = useState(false);

  const updateTemplateStatusEqual = (oldObj, newObj) =>
    oldObj?.updateTemplateStatus?.status === newObj?.updateTemplateStatus?.status;
  const { updateTemplateStatus } = useSelector(getGraphsTemplatesReducerState, updateTemplateStatusEqual);

  useEffect(() => {
    if (!template?.id) {
      dispatch(getGraphsTemplateById(templateId));
    } else {
      setEditingTemplate(template);
    }
  }, [template]);

  useEffect(() => {
    if (isSuccess(updateTemplateStatus.status) && isSaving) {
      setIsSaving(false);
      dispatch(getGraphsTemplateById(templateId));
      setTimeout(() => {
        history.push(`/dashboard/${id}/graph-templates/${templateId}`);
      }, 500);
    }
  }, [updateTemplateStatus]);

  const handleSave = () => {
    setIsSaving(true);
    dispatch(updateGraphsTemplate(editingTemplate));
  };

  function canSave() {
    return editingTemplate?.name && (editingTemplate?.sensors?.length || 0) > 1;
  }

  return (
    <Container maxWidth={false} data-testid="edit-chart-template-details">
      <Box sx={{ mx: '24px', pt: '48px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
              <Button
                variant="text"
                startIcon={<ArrowBackIosRoundedIcon />}
                sx={{ fontSize: 14, p: 0, pt: '2px' }}
                onClick={() => history.goBack()}
                data-testid="edit-chart-template-details-btn-back"
              >
                {t('default_actions.back')}
              </Button>
              <Divider orientation="vertical" flexItem />
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="primary.main"
                  sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}
                  href={`/dashboard/${id}/graph-templates`}
                  data-testid="chart-templates-link"
                >
                  {t('container_chart_template_details.breadcrumb.graphs_templates')}
                </Link>
                <Link
                  underline="hover"
                  color="primary.main"
                  sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}
                  href={`/dashboard/${id}/graph-templates/${templateId}`}
                  data-testid="chart-templates-link"
                >
                  {editingTemplate?.name}
                </Link>
                <Typography
                  component="span"
                  color="text.primary"
                  sx={{ fontSize: { xs: 12, sm: 14, fontWeight: 550 } }}
                >
                  {t('default_actions.edit')}
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' }, mb: 3 }}
          >
            <Button
              variant="contained"
              sx={{
                height: '56px',
                width: { xs: '100%', md: 'auto' }
              }}
              onClick={handleSave}
              startIcon={<SaveOutlinedIcon sx={{ fontSize: '24px !important' }} />}
              data-testid="chart-template-details-btn-options"
              disabled={!canSave()}
            >
              {t('edit_chart_template_details.btn_save_template')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <EditInfoChartTemplateDetails template={editingTemplate} setEditingTemplate={setEditingTemplate} />
          </Grid>
          <Grid item xs={12}>
            <EditPropsChartTemplateDetails template={editingTemplate} setEditingTemplate={setEditingTemplate} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
