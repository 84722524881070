import {
  ContainerChartTemplateDetails,
  ContainerEditChartTemplateDetails
} from 'containers/Dashboard/MachineDetail/GraphsTemplates/ChartTemplateDetails';
import { ROLE_MANAGER } from './roles';
import Account from '../containers/Account';
import ResetPassword from '../containers/Account/ResetPassword';
import Dashboard from '../containers/Dashboard';
import Machines from '../containers/Machines';
import MachinesDetail from '../containers/Machines/MachinesDetail';
import BatchRecords from '../containers/BatchRecords';
import BatchRecordsDetail from '../containers/BatchRecords/BatchRecordsDetail';
import UserManagement from '../containers/UserManagement';
import MachineDetail from '../containers/Dashboard/MachineDetail';
import AlreadyLoggedIn from '../containers/Auth/AlreadyLoggedIn';
import MachineHistory from '../containers/Dashboard/MachineDetail/MachineHistory';
import SensorHistory from '../containers/Dashboard/MachineDetail/SensorHistory';
import SensorComparison from '../containers/Dashboard/MachineDetail/SensorComparison';
import { ROUTE_BATCH_RECORDS, ROUTE_MACHINES } from './routePaths';

const ROUTES = [
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'dashboard',
    topNav: false,
    subNav: {
      main: true
    },
    dataTestId: 'menu-selection-dashboard-button'
  },
  {
    path: `/${ROUTE_BATCH_RECORDS}`,
    component: BatchRecords,
    name: ROUTE_BATCH_RECORDS,
    allow_feature: 'BATCH_RECORDS',
    subNav: {
      main: true
    },
    dataTestId: 'menu-selection-batch-records-button'
  },
  {
    path: `/${ROUTE_BATCH_RECORDS}/:id`,
    component: BatchRecordsDetail,
    name: `${ROUTE_BATCH_RECORDS}-details`,
    allow_feature: 'BATCH_RECORDS'
  },
  {
    path: '/dashboard/:id',
    component: MachineDetail,
    name: 'machine'
  },
  {
    path: '/dashboard/:id/sensors',
    component: MachineDetail,
    name: 'machine'
  },
  {
    path: '/dashboard/:id/graph-templates',
    component: MachineDetail,
    name: 'machine'
  },
  {
    path: '/dashboard/:id/metrics',
    component: MachineDetail,
    name: 'machine'
  },
  {
    path: '/dashboard/:id/alarms',
    component: MachineDetail,
    name: 'machine'
  },
  {
    path: '/dashboard/:id/alerts',
    component: MachineDetail,
    name: 'machine'
  },
  {
    path: '/dashboard/:id/graph-templates/:templateId',
    component: ContainerChartTemplateDetails,
    name: 'chart-template-details'
  },
  {
    path: '/dashboard/:id/graph-templates/:templateId/edit',
    component: ContainerEditChartTemplateDetails,
    name: 'chart-template-details-edit'
  },
  {
    path: '/dashboard/:id/graph-templates',
    component: MachineDetail,
    name: 'machine'
  },
  {
    path: '/dashboard/:id/history',
    component: MachineHistory,
    name: 'machineHistory'
  },
  {
    path: '/dashboard/:id/sensor/compare',
    component: SensorComparison,
    name: 'machineSensorComparison'
  },
  {
    path: '/dashboard/:id/sensor/:sensorType',
    component: SensorHistory,
    name: 'machineSensorHistory'
  },
  {
    path: `/${ROUTE_MACHINES}`,
    component: Machines,
    name: ROUTE_MACHINES,
    topNav: false,
    subNav: {
      main: true
    },
    roles: [ROLE_MANAGER],
    dataTestId: 'menu-selection-machines-button'
  },
  {
    path: `/${ROUTE_MACHINES}/:id`,
    component: MachinesDetail,
    name: 'machines-detail',
    roles: [ROLE_MANAGER]
  },
  {
    path: '/user-management',
    component: UserManagement,
    name: 'user-management',
    subNav: {
      main: false
    },
    roles: [ROLE_MANAGER],
    dataTestId: 'menu-selection-user-management-button'
  },
  {
    path: '/account',
    component: Account,
    name: 'account',
    subNav: {
      main: false
    },
    dataTestId: 'menu-selection-user-account-button'
  },
  {
    path: '/account/reset-password',
    component: ResetPassword,
    name: 'reset_password'
  },
  {
    path: '/register/:token',
    component: AlreadyLoggedIn,
    name: 'already_logged_in'
  }
];

export default ROUTES;
