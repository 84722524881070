import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';
import { NotifyTypo, ResponsiveIconButton, useResolutionCheck } from 'web-components';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import withLoadingState from '../../../../helpers/withLoadingState';
import { ContentWrapper } from '../../../../elements';
import { ContainerPageTitle, DesktopRefreshButton, PageHeader } from '../elements';
import MachineStateSelection from './MachineStateSelection';
import { ContainerTitle, GraphWrapper } from './elements';
import { getData } from '../../../../redux/rootSelectors';
import {
  getSelectedMachine,
  getSelectedMachineSensorComparisonSensorTypes
} from '../../../../redux/machines/selectors';
import { loadComparisonData, loadMachineStatusMetrics } from '../../../../redux/machines/actions';
import EmptyState from '../../../../components/EmptyState';
import SensorComparisonFooter from './SensorComparisonFooter';
import ConnectedSensorComparisonGraph from './ConnectedSensorComparisonGraph';
import TimeSelection from '../../../../components/Graphs/TimeSelection';
import Breadcrumbs from '../../../../components/elements/Breadcrumbs';
import MobileSensorPortrait from '../../../../components/elements/MobileSensorPortrait';
import MachineLegendTooltip from '../MachineLegendTooltip';
import ComparisonDialog from '../ComparisonDialog';

const SensorComparison = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDesktop } = useResolutionCheck();
  const isMobile = useMediaQuery('(max-width:425px) and (orientation:portrait)');

  const selectedSensorTypes = useSelector(getSelectedMachineSensorComparisonSensorTypes);
  const { id: machineId } = useParams();
  const machine = useSelector(compose(getData, getSelectedMachine));
  const [isOpen, setIsOpen] = useState(false);

  const openCompareDialog = () => {
    setIsOpen(!isOpen);
  };

  const reloadData = () => {
    dispatch(loadMachineStatusMetrics(machineId));
    dispatch(loadComparisonData());
  };

  const anySensorSelected = selectedSensorTypes.length > 0;

  const breadcrumbObj = [
    { route: '/dashboard', name: `${t('dashboard.title')}` },
    { route: `/dashboard/${machineId}`, name: `${machine.name}` },
    { route: null, name: `${t('dashboard.sensors.comparison.title')}` }
  ];

  return (
    <>
      <ComparisonDialog showButton={false} isOpen={isOpen} setIsOpen={openCompareDialog} />
      <PageHeader alwaysVisible>
        <Breadcrumbs data={breadcrumbObj} />
        <ContainerPageTitle>
          <PageHeader.Title>
            <PageHeader.Title.Main>{t('dashboard.sensors.comparison.title')}</PageHeader.Title.Main>
          </PageHeader.Title>
        </ContainerPageTitle>
      </PageHeader>
      <ContentWrapper maxWidth={false}>
        <GraphWrapper>
          {anySensorSelected && (
            <ContainerTitle>
              <NotifyTypo.Body2 style={{ fontWeight: '500' }}>
                {t('dashboard.sensors.compare_sensors_title')}
              </NotifyTypo.Body2>
              <div style={{ display: 'flex' }}>
                {!isMobile && <MachineStateSelection mobile={!isDesktop} />}
                {isMobile && <MachineLegendTooltip />}
                {isDesktop ? (
                  <DesktopRefreshButton
                    data-testid="refresh-info-button"
                    buttonText={t('dashboard.sensors.refresh_info')}
                    iconName="reload"
                    color="primary"
                    onClick={reloadData}
                  />
                ) : (
                  <ResponsiveIconButton
                    data-testid="refresh-info-button"
                    buttonText={t('dashboard.sensors.refresh_info')}
                    iconName="reload"
                    color="primary"
                    onClick={reloadData}
                    style={{ marginLeft: '1rem' }}
                  />
                )}
              </div>
            </ContainerTitle>
          )}

          {anySensorSelected ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {isMobile && <MachineStateSelection isMobile={isMobile} mobile />}
              <TimeSelection machineId={machineId} sensorTypes={selectedSensorTypes} />
              {isMobile ? (
                <MobileSensorPortrait>
                  <SensorComparisonFooter mobile setIsOpen={openCompareDialog} />
                </MobileSensorPortrait>
              ) : (
                <ConnectedSensorComparisonGraph />
              )}
            </div>
          ) : (
            <>
              <EmptyState type="sensors_comparison_noSensorsSelected" />
              {isMobile && <SensorComparisonFooter mobile setIsOpen={openCompareDialog} />}
            </>
          )}
          {!isMobile && <SensorComparisonFooter setIsOpen={openCompareDialog} />}
        </GraphWrapper>
      </ContentWrapper>
    </>
  );
};

export default withLoadingState(SensorComparison);
